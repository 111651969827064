import {Computed, DataAction, StateRepository} from "@angular-ru/ngxs/decorators";
import {State} from "@ngxs/store";
import {ValveType} from "../models";
import {Injectable} from "@angular/core";
import {NgxsDataRepository} from "@angular-ru/ngxs/repositories";
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";
import {ProductStateModel} from "src/app/core/models/product/product-state.model";
import {ProductService} from "../services";

@StateRepository()
@State<ProductStateModel>({
  name: 'products',
  defaults: {
    valveTypes: []
  }
})
@Injectable({
  providedIn: 'root'
})
export class ProductState extends NgxsDataRepository<ProductStateModel> {

  constructor(private readonly productSvc: ProductService) {
    super();
  }

  @Computed()
  public get availableValveTypes$() {
    return this.state$.pipe(map((state) => state ? state.valveTypes : []));
  }

  @DataAction()
  public loadValveTypes(): Observable<ValveType[]> {
    return this.productSvc.loadValveTypes().pipe(tap((vts: ValveType[]): void => {
      this.patchState({
        valveTypes: vts
      });
    }));
  }
}
