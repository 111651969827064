import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDrawerModule,
  DxDropDownButtonModule,
  DxFileUploaderModule,
  DxFilterBuilderModule,
  DxGalleryModule,
  DxHtmlEditorModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxMultiViewModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxProgressBarModule,
  DxSelectBoxModule,
  DxSpeedDialActionModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTileViewModule,
  DxTooltipModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { ReplaceDotPipe } from 'src/app/shared/pipes/replace_dot-pipe';
import { TrimPipe } from 'src/app/shared/pipes/trim-pipe';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { RemoteUploaderComponent } from './components/remote-uploader/remote-uploader.component';
import { PerformanceChartComponent } from './components/performance-chart/performance-chart.component';
import { TitleComponent } from './components/title/title.component';
import { BoxesComponent } from './components/boxes/boxes.component';
import { FlexModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { ViewAllLayoutComponent } from './components/view-all-layout/view-all-layout.component';
import { SingleBoxComponent } from './components/single-box/single-box.component';
import { BiasOptionComponent } from './components/bias-option/bias-option.component';
import { FlowAdjustmentOptionComponent } from './components/flow-adjustment-option/flow-adjustment-option.component';
import { ManualOverrideOptionComponent } from './components/manual-override-option/manual-override-option.component';
import { BaseCodeOptionComponent } from './components/base-code-option/base-code-option.component';
import { FlowSettingRangeOptionComponent } from './components/flow-setting-range-option/flow-setting-range-option.component';
import { FixedFlowSettingComponent } from './components/fixed-flow-setting/fixed-flow-setting.component';
import { PowerOptionComponent } from './components/power-option/power-option.component';
import { FiltrationOptionComponent } from './components/filtration-option/filtration-option.component';
import { SteelWireOptionComponent } from './components/steel-wire-option/steel-wire-option.component';
import { LabellingOptionComponent } from './components/labelling-option/labelling-option.component';
import { ConnectorOptionComponent } from './components/connector-option/connector-option.component';
import { FlowPathOptionComponent } from './components/flow-path-option/flow-path-option.component';
import { ConfigCodeOptionComponent } from './components/config-code-option/config-code-option.component';
import { VacRacVdcOptionComponent } from './components/vac-rac-vdc-option/vac-rac-vdc-option.component';
import { TypeCurrentOptionComponent } from './components/type-current-option/type-current-option.component';
import { TechnicalDataTableComponent } from './components/technical-data-table/technical-data-table.component';
import { VisButtonComponent } from './components/vis-button/vis-button.component';

@NgModule({
  declarations: [
    LanguageSelectorComponent,
    StepperComponent,
    RemoteUploaderComponent,
    PerformanceChartComponent,
    TrimPipe,
    ReplaceDotPipe,
    TitleComponent,
    BoxesComponent,
    ViewAllLayoutComponent,
    SingleBoxComponent,
    BiasOptionComponent,
    FlowAdjustmentOptionComponent,
    ManualOverrideOptionComponent,
    BaseCodeOptionComponent,
    FlowSettingRangeOptionComponent,
    FixedFlowSettingComponent,
    SteelWireOptionComponent,
    FiltrationOptionComponent,
    FlowPathOptionComponent,
    ConfigCodeOptionComponent,
    LabellingOptionComponent,
    ConnectorOptionComponent,
    PowerOptionComponent,
    VacRacVdcOptionComponent,
    TypeCurrentOptionComponent,
    TechnicalDataTableComponent,
    VisButtonComponent,
  ],
  exports: [
    LanguageSelectorComponent,
    StepperComponent,
    RemoteUploaderComponent,
    PerformanceChartComponent,
    TrimPipe,
    ReplaceDotPipe,
    TitleComponent,
    BoxesComponent,
    ViewAllLayoutComponent,
    BiasOptionComponent,
    FlowAdjustmentOptionComponent,
    ManualOverrideOptionComponent,
    BaseCodeOptionComponent,
    FlowSettingRangeOptionComponent,
    FixedFlowSettingComponent,
    SteelWireOptionComponent,
    FiltrationOptionComponent,
    FlowPathOptionComponent,
    ConfigCodeOptionComponent,
    LabellingOptionComponent,
    ConnectorOptionComponent,
    PowerOptionComponent,
    VacRacVdcOptionComponent,
    TypeCurrentOptionComponent,
    TechnicalDataTableComponent,
    VisButtonComponent,
  ],
  imports: [
    CommonModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxFileUploaderModule,
    DxChartModule,
    DxMultiViewModule,
    FlexModule,
    TranslocoModule,
    RouterModule,
    DxGalleryModule,
    DxFormModule,
    DxToolbarModule,
    DxDataGridModule,
    DxDrawerModule,
    DxDropDownButtonModule,
    DxFilterBuilderModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    DxTabPanelModule,
    DxPopupModule,
    DxScrollViewModule,
    DxHtmlEditorModule,
    DxTextAreaModule,
    DxFileUploaderModule,
    DxLoadPanelModule,
    DxProgressBarModule,
    DxSwitchModule,
    DxChartModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxNumberBoxModule,
    DxValidatorModule,
    DxSpeedDialActionModule,
    DxTileViewModule,
    DxTooltipModule,
  ],
})
export class SharedModule {}
