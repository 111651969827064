import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxButtonComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MainTypes } from 'src/app/core/models';
import { HomeBoxes } from 'src/app/core/models/home/boxes';
import { ProductService, ScreenService } from 'src/app/core/services';
import { HomeService } from '../../../core/services/home.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy {
  @ViewChild('selectMainType') selectBtn: DxButtonComponent | undefined;
  prodMainTypes: MainTypes[];
  //tabs = ['News', 'Events', 'CSR', 'Welfare Activities'/*, 'Sponsorship'*/];
  tabs: string[] = [];
  aboutUsBoxes: HomeBoxes[] = [];
  supportsBoxes: HomeBoxes[] = [];
  limit = 4;
  videoUrl!: string;
  selectedTabIndex = 0;
  mainTypeChooserButtonDisabled = true;
  boxes: any[] = [];
  loading = true;
  viewAllLink = '/news';

  isMobile: boolean = this.scrnSvc.isMobile;

  destroyed = new Subject<void>();

  constructor(
    private prodSvc: ProductService,
    private homeService: HomeService,
    private scrnSvc: ScreenService
  ) {
    this.prodMainTypes = this.prodSvc.getMainTypes();

    scrnSvc.changed.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.isMobile = scrnSvc.isMobile;
    });
  }

  ngOnInit(): void {
    this.homeService
      .getNewsBoxes(this.limit)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.length) {
          this.tabs[0] = 'News';
        }
        this.boxes = res;
        this.loading = false;
      });
    this.homeService
      .getEventsBoxes(this.limit)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.length) {
          this.tabs[1] = 'Events';
        }
      });
    this.homeService
      .getCsrBoxes(this.limit)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.length) {
          this.tabs[2] = 'Csr';
        }
      });
    this.homeService
      .getWelfareBoxes(this.limit)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.length) {
          this.tabs[3] = 'Welfare';
        }
      });
    this.aboutUsBoxes = this.homeService.getHomeBoxes('aboutUs');
    this.supportsBoxes = this.homeService.getHomeBoxes('supports');
    this.videoUrl = this.homeService.videoUrl();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  changeTab(selected: number) {
    if (this.selectedTabIndex !== selected) {
      this.loading = true;
      this.selectedTabIndex = selected;
      switch (selected) {
        case 0:
          this.viewAllLink = '/news';
          this.homeService.getNewsBoxes(this.limit).subscribe((res) => {
            this.boxes = res;
            this.loading = false;
          });
          break;
        case 1:
          this.viewAllLink = '/past-events';
          this.homeService.getEventsBoxes(this.limit).subscribe((res) => {
            this.boxes = res;
            this.loading = false;
          });
          break;
        case 2:
          this.viewAllLink = '/csr';
          this.homeService.getCsrBoxes(this.limit).subscribe((res) => {
            this.boxes = res;
            this.loading = false;
          });
          break;
        case 3:
          this.viewAllLink = '/welfare';
          this.homeService.getWelfareBoxes(this.limit).subscribe((res) => {
            this.boxes = res;
            this.loading = false;
          });
          break;
        case 4:
          this.viewAllLink = '/sponsorships';
          this.homeService.getNewsBoxes(this.limit).subscribe((res) => {
            this.boxes = res;
            this.loading = false;
          });
          break;
      }
    }
  }

  scrollToNextSection() {
    const el = document.getElementById('home-tabs');
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  onOpenConfigurator() {
    document
      .getElementsByClassName('home-back')[0]
      .classList.add('zero-margin');
  }
}
