import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth-guard.guard';
import {
  AdminLayoutComponent,
  WebsiteLayoutComponent,
} from 'src/app/layout/components';
import { HomePageComponent } from 'src/app/website/home/components/home-page.component';
import { PermissionGuard } from './core/guards/permission-guard.guard';

const routes: Routes = [
  {
    path: '',
    component: WebsiteLayoutComponent,
    children: [
      {
        path: '',
        component: HomePageComponent,
        data: {
          title: 'VisHydraulics',
          description: '',
        },
      },
      {
        path: 'about-us',
        data: {
          title: 'VisHydraulics - About us',
          description: '',
        },
        loadChildren: () =>
          import('src/app/website/about-us/about-us.module').then(
            (m) => m.AboutUsModule
          ),
      },
      {
        path: 'download-area',
        data: {
          title: 'VisHydraulics - Downloads',
          description: '',
        },
        loadChildren: () =>
          import('src/app/website/download-area/download-area.module').then(
            (m) => m.DownloadAreaModule
          ),
      },
      {
        path: 'job-opportunities',
        data: {
          title: 'VisHydraulics - Job opportunities',
          description: '',
        },
        loadChildren: () =>
          import(
            'src/app/website/job-opportunities/job-opportunities.module'
          ).then((m) => m.JobOpportunitiesModule),
      },
      {
        path: 'production-technology',
        data: {
          title: 'VisHydraulics - Production technology',
          description: '',
        },
        loadChildren: () =>
          import(
            'src/app/website/production-technology/production-technology.module'
          ).then((m) => m.ProductionTechnologyModule),
      },
      {
        path: 'product',
        loadChildren: () =>
          import('src/app/website/product/product.module').then(
            (m) => m.ProductModule
          ),
      },
      {
        path: 'sales-distribution',
        data: {
          title: 'VisHydraulics - Sales distribution',
          description: '',
        },
        loadChildren: () =>
          import('src/app/website/sales/sales.module').then(
            (m) => m.SalesModule
          ),
      },
      {
        path: 'applications',
        data: {
          title: 'VisHydraulics - Applications',
          description: '',
        },
        loadChildren: () =>
          import('src/app/website/applications/applications.module').then(
            (m) => m.ApplicationsModule
          ),
      },
      {
        path: 'innovation-center',
        data: {
          title: 'VisHydraulics - Innovation center',
          description: '',
        },
        loadChildren: () =>
          import(
            'src/app/website/innovation-center/innovation-center.module'
          ).then((m) => m.InnovationCenterModule),
      },
      {
        path: 'scip-code',
        loadChildren: () =>
          import('src/app/website/scip-code/scip-code.module').then(
            (m) => m.ScipCodeModule
          ),
      },
      {
        path: 'past-events',
        data: {
          title: 'VisHydraulics - Past events',
          description: '',
        },
        loadChildren: () =>
          import('src/app/website/past-events/past-events.module').then(
            (m) => m.PastEventsModule
          ),
      },
      {
        path: 'news',
        data: {
          title: 'VisHydraulics - News',
          description: '',
        },
        loadChildren: () =>
          import('./website/news/news.module').then((m) => m.NewsModule),
      },
      {
        path: 'csr',
        data: {
          title: 'VisHydraulics - CSR',
          description: '',
        },
        loadChildren: () =>
          import('./website/csr/csr.module').then((m) => m.CsrModule),
      },
      {
        path: 'welfare',
        data: {
          title: 'VisHydraulics - Welfare',
          description: '',
        },
        loadChildren: () =>
          import('./website/welfare/welfare.module').then(
            (m) => m.WelfareModule
          ),
      },

      {
        path: 'terms-and-conditions',
        data: {
          title: 'VisHydraulics - Terms and Conditions',
          description: '',
        },
        loadChildren: () =>
          import(
            'src/app/website/terms-conditions/terms-conditions.module'
          ).then((m) => m.TermsConditionsModule),
      },
      {
        path: 'privacy-policy',
        data: {
          title: 'VisHydraulics - Privacy policy',
          description: '',
        },
        loadChildren: () =>
          import('./website/privacy-policy/privacy-policy.module').then(
            (m) => m.PrivacyPolicyModule
          ),
      },
      {
        path: 'privacy-policy-candidates',
        loadChildren: () =>
          import(
            './website/privacy-policy-candidates/privacy-policy-candidates.module'
          ).then((m) => m.PrivacyPolicyCandidatesModule),
      },
      {
        path: 'access-information-policy',
        loadChildren: () =>
          import(
            './website/access-information-policy/access-information-policy.module'
          ).then((m) => m.AccessInformationPolicyModule),
      },
      {
        path: 'cookie-policy',
        loadChildren: () =>
          import('./website/cookie-policy/cookie-policy.module').then(
            (m) => m.CookiePolicyModule
          ),
      },
      {
        path: 'policy-fornitori',
        loadChildren: () =>
          import('./website/policy-fornitori/policy-fornitori.module').then(
            (m) => m.PolicyFornitoriModule
          ),
      },
      {
        path: 'policy-eventi',
        loadChildren: () =>
          import('./website/policy-eventi/policy-eventi.module').then(
            (m) => m.PolicyEventiModule
          ),
      },
    ],
  },
  {
    path: 'auth',
    data: {
      title: 'Login - VisHydraulics',
      description: '',
    },
    loadChildren: () =>
      import('src/app/auth/auth.module').then((m) => m.AuthModule),
  },
  // Admin routes
  {
    path: 'admin',
    data: {
      title: 'Admin - VisHydraulics',
      description: '',
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: AdminLayoutComponent,
    children: [
      {
        path: 'config',
        canActivate: [PermissionGuard],
        data: { permission: ['ADMIN'] },
        loadChildren: () =>
          import('src/app/admin/config/config.module').then(
            (m) => m.ConfigModule
          ),
      },
      {
        path: 'product',
        canActivate: [PermissionGuard],
        data: { permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'] },
        loadChildren: () =>
          import('src/app/admin/product/product.module').then(
            (m) => m.ProductModule
          ),
      },
      {
        path: 'profile',
        canActivate: [PermissionGuard],
        data: { permission: ['*'] },
        loadChildren: () =>
          import('src/app/admin/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'news',
        canActivate: [PermissionGuard],
        data: { permission: ['ADMIN'] },
        loadChildren: () =>
          import('src/app/admin/news/news.module').then((m) => m.NewsModule),
      },
      {
        path: 'job-opportunities',
        canActivate: [PermissionGuard],
        data: { permission: ['ADMIN'] },
        loadChildren: () =>
          import(
            'src/app/admin/job-opportunities/job-opportunities-admin.module'
          ).then((m) => m.JobOpportunitiesAdminModule),
      },
      {
        path: 'events',
        canActivate: [PermissionGuard],
        data: { permission: ['ADMIN'] },
        loadChildren: () =>
          import('src/app/admin/events/events-admin.module').then(
            (m) => m.EventsAdminModule
          ),
      },
      {
        path: 'csr',
        canActivate: [PermissionGuard],
        data: { permission: ['ADMIN'] },
        loadChildren: () =>
          import('./admin/csr/csr.module').then((m) => m.CsrModule),
      },
      {
        path: 'welfare',
        canActivate: [PermissionGuard],
        data: { permission: ['ADMIN'] },
        loadChildren: () =>
          import('./admin/welfare/welfare.module').then((m) => m.WelfareModule),
      },
      {
        path: 'sponsorship',
        canActivate: [PermissionGuard],
        data: { permission: ['ADMIN'] },
        loadChildren: () =>
          import('./admin/sponsorship/sponsorship.module').then(
            (m) => m.SponsorshipModule
          ),
      },
      {
        path: 'prod-tech',
        canActivate: [PermissionGuard],
        data: { permission: ['ADMIN'] },
        loadChildren: () =>
          import('src/app/admin/prod-tech/prod-tech.module').then(
            (m) => m.ProdTechModule
          ),
      },
      {
        path: 'about-us',
        canActivate: [PermissionGuard],
        data: { permission: ['ADMIN'] },
        loadChildren: () =>
          import('./admin/about-us/about-us.module').then(
            (m) => m.AboutUsModule
          ),
      },
    ],
  },
  {
    path: '404-not-found',
    component: WebsiteLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/website/not-found/not-found.module').then(
            (m) => m.NotFoundModule
          ),
      },
    ],
  },
  { path: '**', redirectTo: '404-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
