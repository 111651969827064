export interface ValveLang {
  id: number | null,
  idRevision: number | null,
  idLang: number | null,
  auditUser: string | null,
  basicCodeDescription: string
  cavityCodeDescription: string,
  markingCodeDescription: string,
  configCodeDescription: string,
  subtitle1: string,
  subtitle2: string,
  subtitle3: string,
  note : string,
  description: string,
  tecnicalFeatures: string,
  chartNoteFP: string,
  chartNotePF: string,
  chartNoteCF: string,
  chartNoteOL: string,
  orderingCodeGlobalNote: string,
  fixedFlowSettingNote: string,
  filtrationNote: string,
  flowPathNote: string,
  flowAdjustmentNote: string,
  manualOverrideNote: string,
  externalDimensionNote1: string,
  externalDimensionNote2: string,
  externalDimensionNote3: string,
  availableNut1: string,
  availableNut2: string,
  availableNut3: string
}

export function emptyValveLang(idLang: number): ValveLang {
  return {
    id: null,
    idRevision: null,
    idLang: idLang,
    auditUser: null,
    basicCodeDescription: '',
    cavityCodeDescription: '',
    markingCodeDescription: '',
    configCodeDescription: '',
    subtitle1: '',
    subtitle2: '',
    subtitle3: '',
    note: '',
    description: '',
    tecnicalFeatures: '',
    chartNoteFP: '',
    chartNotePF: '',
    chartNoteCF: '',
    chartNoteOL: '',
    orderingCodeGlobalNote: '',
    fixedFlowSettingNote: '',
    filtrationNote: '',
    flowPathNote: '',
    flowAdjustmentNote: '',
    manualOverrideNote: '',
    externalDimensionNote1: '',
    externalDimensionNote2: '',
    externalDimensionNote3: '',
    availableNut1: '',
    availableNut2: '',
    availableNut3: ''
  };
}

