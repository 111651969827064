<ng-container *transloco="let t; scope: 'website'">
  <div class="keen-slider" #sliderRef *ngIf="type === 0 || type === 1">
    <div *ngFor="let box of tab; let i = index" class="keen-slider__slide">
      <ng-container *ngIf="!box.isLast">
        <app-single-box [type]="type" [box]="box" [boxType]="viewAllLink"></app-single-box>
      </ng-container>

      <ng-container *ngIf="box.isLast">
        <a [routerLink]="viewAllLink" [ngStyle]="{ height: '100%', 'text-decoration': 'none' }">
          <div class="itc_box" fxLayout="column" fxLayoutAlign="center center">
            <div class="itc_view_all">
              <span class="itc_view_all_font_top">{{
                t("view_all") | uppercase
                }}</span>
              <div class="itc_divisor"></div>
              <span class="itc_view_all_font_bottom">{{
                t(viewAllLink.substring(1)) | uppercase
                }}</span>
            </div>
          </div>
        </a>
      </ng-container>
    </div>
  </div>
  <div class="keen-slider" #sliderRef *ngIf="type === 2 || type === 3">
    <div [ngStyle]="type === 3 ? { 'background-color': '#ffffff' } : {}" *ngFor="let boxes of tab; let i = index"
      class="itc_support_item keen-slider__slide">
      <a *ngIf="boxes.link" class="itc_support_image" [ngStyle]="{ 'background-image': 'url(' + boxes.image + ')' }"
        [href]="boxes.link" target="_blank">
      </a>
      <div *ngIf="!boxes.link" class="itc_support_image" [ngStyle]="{ 'background-image': 'url(' + boxes.image + ')' }">
      </div>
    </div>
  </div>
</ng-container>