import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ScreenService } from 'src/app/core/services';
import KeenSlider from 'keen-slider';

@Component({
  selector: 'app-boxes',
  templateUrl: './boxes.component.html',
  styleUrls: [
    '../../../../../node_modules/keen-slider/keen-slider.min.css',
    './boxes.component.scss',
  ],
})
export class BoxesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() tab: any[] = [];
  @Input() type: number | undefined;
  @Input() viewAllLink = '';
  @Input() visibleSlides = 4;

  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;

  slider: any = null;

  count = 0;
  isMobile = this.scrnSvc.isMobile;

  constructor(private scrnSvc: ScreenService) {
    this.scrnSvc.changed.subscribe(() => {
      this.isMobile = this.scrnSvc.isMobile;
    });
  }

  ngOnInit() {
    this.count = this.tab.length;
    const lastItem = this.tab.filter((item: any) => {
      return item.isLast === true;
    });
    if (this.type === 0 && !lastItem.length && this.tab.length > 3)
      this.tab.push({ isLast: true });
  }

  ngAfterViewInit(): void {
    if (this.type == 2 || this.type == 3) {
      this.slider = new KeenSlider(
        this.sliderRef?.nativeElement,
        {
          loop: true,
          slides: {
            perView: this.visibleSlides,
            spacing: 15,
          },
          breakpoints: {
            '(max-width: 400px)': {
              slides: { perView: 1, spacing: 0 },
            },
            '(min-width: 400px) and (max-width: 800px)': {
              slides: { perView: 2, spacing: 15 },
            },
          },
        },
        [
          (slider) => {
            let timeout: any;
            let mouseOver = false;
            function clearNextTimeout() {
              clearTimeout(timeout);
            }
            function nextTimeout() {
              clearTimeout(timeout);
              if (mouseOver) return;
              timeout = setTimeout(() => {
                slider.next();
              }, 2000);
            }
            slider.on('created', () => {
              slider.container.addEventListener('mouseover', () => {
                mouseOver = true;
                clearNextTimeout();
              });
              slider.container.addEventListener('mouseout', () => {
                mouseOver = false;
                nextTimeout();
              });
              nextTimeout();
            });
            slider.on('dragStarted', clearNextTimeout);
            slider.on('animationEnded', nextTimeout);
            slider.on('updated', nextTimeout);
          },
        ]
      );
    } else {
      this.slider = new KeenSlider(this.sliderRef?.nativeElement, {
        slides: {
          perView: 4,
          spacing: 15,
        },
        breakpoints: {
          '(max-width: 600px)': {
            slides: { perView: 1 },
          },
          '(min-width: 600px) and (max-width: 1200px)': {
            slides: { perView: 2, spacing: 15 },
          },
          '(min-width: 1200px) and (max-width: 1750px)': {
            slides: { perView: 3, spacing: 15 },
          },
        },
      });
    }

    setTimeout(() => {
      this.slider.update();
    });
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy();
  }
}
