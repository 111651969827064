import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {DxDropDownButtonModule, DxListModule} from 'devextreme-angular';
import {DxDrawerModule} from 'devextreme-angular/ui/drawer';
import {DxScrollViewModule} from 'devextreme-angular/ui/scroll-view';
import {DxTextBoxModule} from 'devextreme-angular/ui/text-box';
import {DxToolbarModule} from 'devextreme-angular/ui/toolbar';
import {DxTreeViewModule} from 'devextreme-angular/ui/tree-view';
import {SharedModule} from 'src/app/shared/shared.module';
import {
  AdminFooterComponent,
  AdminHeaderComponent,
  AdminLayoutComponent,
  AdminSideNavigationMenuComponent,
  AdminSideNavInnerToolbarComponent,
  PageLoaderComponent,
  WebsiteFooterComponent,
  WebsiteHeaderComponent,
  WebsiteLayoutComponent,
  WebsiteMainMenuComponent,
  WebsiteRightToolbarComponent,
} from './components';

@NgModule({
  declarations: [
    AdminFooterComponent,
    AdminHeaderComponent,
    AdminLayoutComponent,
    AdminSideNavInnerToolbarComponent,
    AdminSideNavigationMenuComponent,
    PageLoaderComponent,
    WebsiteFooterComponent,
    WebsiteHeaderComponent,
    WebsiteLayoutComponent,
    WebsiteRightToolbarComponent,
    WebsiteMainMenuComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    TranslocoModule,
    DxToolbarModule,
    DxDrawerModule,
    DxScrollViewModule,
    DxTreeViewModule,
    DxTextBoxModule,
    DxDropDownButtonModule,
    DxListModule,
    SharedModule,
  ],
  exports: [AdminLayoutComponent, WebsiteLayoutComponent],
})
export class LayoutModule {}
