import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import CustomStore from 'devextreme/data/custom_store';
import {
  ApiResponse,
  BiasOption,
  CoiningKit,
  CompleteValve,
  CompleteValveLang,
  FixedFlowSettings,
  GetValveCategCallback,
  GetValveCodeCallback,
  GetValveIdCallback,
  GetValveLangIdCallback,
  GetValveRevisionIdCallback,
  GetValveTypeIdCallback,
  LoadedCallback,
  MainTypes,
  NewRevisionRequest,
  ProductFilter,
  UpdateRequest,
  UpdateValueType,
  Valve,
  ValveGridData,
  ValveRevision,
} from 'src/app/core/models';
import { ApplicationDetails } from 'src/app/core/models/applications';
import GraphQLResponse from 'src/app/core/models/graphql-response';
import { ValveChart } from 'src/app/core/models/product/valve_chart.model';
import { ValveWebGridData } from 'src/app/core/models/product/valve_web_grid.model';
import { EndpointService } from 'src/app/core/services/endpoint.service';
import { GridCustomStoreService } from 'src/app/core/services/grid-custom-store.service';
import { ProductQueries } from 'src/app/core/services/queries';
import { LangState } from 'src/app/core/state';
import { ValveType } from 'src/app/core/models/product/valve_type.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { QuoteMailModel } from '../models/mails/quote-mail.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { ValveCatalogGrid } from '../models/product/valve_catalog_grid.model';
import { IProductReference } from '../models/product/product_reference.model';
import { IUpdateVerification } from '../models/product/update_verification.model';
import { IBaseOptions } from '../models/product/base_options.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  @SelectSnapshot(LangState.selectedLang) currentLang!: number;
  product$: BehaviorSubject<ValveGridData[]> = new BehaviorSubject<
    ValveGridData[]
  >([]);

  constructor(
    private http: HttpClient,
    private epSvc: EndpointService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private transloco: TranslocoService,
    private gridCsSvc: GridCustomStoreService,
    private apollo: Apollo
  ) {}

  loadValveTypes(): Observable<ValveType[]> {
    return this.http
      .get<ApiResponse>(this.epSvc.getApiEndpoint('valve/types'), {
        params: { idLang: this.currentLang },
      })
      .pipe(
        map((response) => {
          if (response.status === 'SUCCESS') {
            return response.payload;
          } else {
            this.logger.error(response.payload);
            this.toastr.error(
              this.transloco.translate('errors.cannot_load_valve_type')
            );
          }
        })
      );
  }

  loadValveTypesByCode(code: string): Observable<ValveType[]> {
    return this.http
      .get<ApiResponse>(this.epSvc.getApiEndpoint('valve/type-by-code'), {
        params: new HttpParams().set('code', code),
      })
      .pipe(
        map((response) => {
          if (response.status === 'SUCCESS') {
            return response.payload;
          } else {
            this.logger.error(response.payload);
            this.toastr.error(
              this.transloco.translate('errors.cannot_load_valve_type')
            );
          }
        })
      );
  }

  loadApplications(): ApplicationDetails[] {
    const apps: ApplicationDetails[] = [
      {
        id: 'directional_control_valves',
        title: 'Directional control valves',
        htmlContent:
          '<p><b>The Vis product range is ideally suited for directional Control valves applications.</b></p><p>Our wide variety of direct acting shock valves (with or without anti-cavitation) and anti-voids provides great design flexibility and excellent performance for use as workport RV’s. For high end (high flow & pressure) applications, rely on our pilot operated relief and anti-cavitation relief valve range for cutting edge performance.</p><p><b>Additional functions such as Main RV, load sense RV, electrical clamp, are also available in various flow and pressure ranges.</b></p>',
      },
      {
        id: 'mini_power_packs',
        title: 'Mini power packs',
        htmlContent:
          '<p><b>Vis can fully equip mini or micro power packs with the requested cartridge valve functions.</b></p><p> Look for the most competitive package, which includes main RV, pressure compensate flow control, in line check valve, ON/OFF solenoid valves with several manual override options.</p><p><b>No matter if your application is essential/very cost competitive rather than high-end performance, we can cover your needs.</b></p>',
      },
      {
        id: 'hydraulics_pump',
        title: 'Hydraulic pumps',
        htmlContent:
          '<p><b>Hydraulic Pumps are a very popular user for the Vis products.</b></p><p>Ranging in pressure from very low (10 bar) all the way to medium/high (450 bar or higher), our Relief Valve range can satisfy your application’s requirements.</p><p><b>Browse in our catalogue to look for the proper flow and pressure range, look for a smooth & silent valve or possibly find an essential & very cost competitive product.</b></p>',
      },
      {
        id: 'hydraulic_motors',
        title: 'Hydraulic motors',
        htmlContent:
          '<p><b>Vis products are commonly used for Hydraulic piston and gear Motors.</b></p><p>Ranging in pressure from very low (10 bar) all the way to medium/high (450 bar or higher), our Relief Valve range can satisfy your application’s requirements.</p><p><b>Browse in our catalogue to look for the proper flow and pressure range, look for a smooth & silent valve or possibly find an essential & very cost competitive product.</b></p>',
      },
      {
        id: 'hydraulic_integrated_circuits',
        title: 'Hydraulic integrated circuits',
        htmlContent:
          '<p><b>Get the best out of the wide Vis product portfolio.</b></p><p>HIC’s design primarily require a great deal of flexibility, versatility and cutting edge performance. We don’t expect your application to compromise when it comes to providing the best possible solution for your customer’s requirements.</p><p><b>The Vis cartridge range can greatly support you with the right product for each requested function.</b></p>',
      },
      {
        id: 'counter_balance_valves',
        title: 'Counter balance valves',
        htmlContent:
          '<p><b>Extremely popular for applications such as excavators and cranes, counter balance valves (Parts in Body concept) do require auxiliary functions to be integrated in the main manifold.</b></p><p>Vis is quite familiar with this type of requirements and can offer and recommend the appropriate item for each specific function.</p>',
      },
    ];

    return apps;
  }

  filterProduct(filter: ProductFilter) {
    return this.http
      .post<ApiResponse>(this.epSvc.getApiEndpoint('valve/list'), filter)
      .pipe(
        map((response) => {
          if (response.status === 'SUCCESS') {
            return response.payload;
          } else {
            this.logger.error(response.payload);
            this.toastr.error(
              this.transloco.translate('errors.cannot_load_valve')
            );
          }
        })
      );
  }

  loadCoiningKit(): Observable<CoiningKit[]> {
    return this.http
      .get<ApiResponse>(this.epSvc.getApiEndpoint('valve/coining-kit  '))
      .pipe(
        map((response) => {
          if (response.status === 'SUCCESS') {
            return response.payload;
          } else {
            this.logger.error(response.payload);
            this.toastr.error(
              this.transloco.translate('errors.cannot_load_coining_kit')
            );
          }
        })
      );
  }

  loadValveRevision(
    valveRevisionId: number
  ): Observable<GraphQLResponse<CompleteValve>> {
    return this.apollo
      .watchQuery<{ valveRevision: CompleteValve }>({
        query: ProductQueries.COMPLTETE_VALVE(
          valveRevisionId,
          this.currentLang
        ),
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((response) => {
          return {
            loading: response.loading,
            error: response.error,
            data: response.data.valveRevision,
          };
        })
      );
  }

  loadValveLang(
    valveRevisionId: number,
    langId: number
  ): Observable<GraphQLResponse<CompleteValveLang>> {
    return this.apollo
      .watchQuery<{ valveRevision: CompleteValveLang }>({
        query: ProductQueries.COMPLETE_VALVE_LANG(valveRevisionId, langId),
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((response) => {
          return {
            loading: response.loading,
            error: response.error,
            data: response.data.valveRevision,
          };
        })
      );
  }

  export(revision: number, valveId: number): Observable<any> {
    const headers = new HttpHeaders().set(
      'Accept',
      'application/octet-stream; charset=utf-8'
    );

    return this.http.get<any>(
      this.epSvc.getApiEndpoint(
        'valve/image?id=' +
          revision +
          '&valve_id=' +
          valveId +
          '&type=STEP_FILE'
      ),
      {
        headers: headers,
        responseType: 'blob' as 'json',
      }
    );
  }

  updateField(req: UpdateRequest, table: string): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(this.epSvc.getApiEndpoint(table), req);
  }

  saveValve(valve: Valve): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.epSvc.getApiEndpoint('valve'),
      valve
    );
  }

  deleteValve(
    idValve: number,
    idRevision: number,
    action: string
  ): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.epSvc.getApiEndpoint('valve') +
        '/' +
        idValve +
        '/' +
        idRevision +
        '/' +
        action
    );
  }

  getSolenoidValvesGroups(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.epSvc.getApiEndpoint('valve/group-type-solenoid')
    );
  }

  updateValveTypeOptions(req: UpdateRequest): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(
      this.epSvc.getApiEndpoint('valve/type-options'),
      req
    );
  }

  createNewRevision(request: NewRevisionRequest): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.epSvc.getApiEndpoint('valve/revision'),
      request
    );
  }

  changeActiveRevision(revisionId: number): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(
      this.epSvc.getApiEndpoint('valve/revision/active'),
      { revisionId: revisionId }
    );
  }

  getTypesList() {
    return this.http
      .get<ApiResponse>(this.epSvc.getApiEndpoint('valve/types'), {
        params: { idLang: this.currentLang },
      })
      .pipe(
        map((res) => {
          return res.payload.map((i: any) => {
            return {
              id: i.id,
              name: i.name,
              type: 'type',
            };
          });
        })
      );
  }

  getValveByType(typeId: number) {
    return this.http
      .get<ApiResponse>(this.epSvc.getApiEndpoint(`valve/bytype/${typeId}`))
      .pipe(
        map((res) => {
          return res.payload.map((i: any) => {
            return {
              id: i.id,
              name: `${i.basicCode}.${i.cavityCode}`,
              type: 'valve',
            };
          });
        })
      );
  }

  getValveRevisionByValveId(idValve: number) {
    return this.http.get<ApiResponse>(
      this.epSvc.getApiEndpoint(`valve/getrevision/${idValve}`)
    );
  }

  getMainTypes(): MainTypes[] {
    const mainTypes: MainTypes[] = [];

    mainTypes.push({
      code: 'check_valves',
      name: 'product.product-main-types.check_valves',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'flow_controls',
      name: 'product.product-main-types.flow_controls',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'pilot_check_valves',
      name: 'product.product-main-types.pilot_check_valves',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'po_directional_valves',
      name: 'product.product-main-types.po_directional_valves',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'po_relief_and_anti',
      name: 'product.product-main-types.po_relief_and_anti',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'po_relief_valves',
      name: 'product.product-main-types.po_relief_valves',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'pressure_compensator',
      name: 'product.product-main-types.pressure_compensator',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'pressure_reducing_valves',
      name: 'product.product-main-types.pressure_reducing_valves',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'proportional_solenoid_valves',
      name: 'product.product-main-types.proportional_solenoid_valves',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'relief_and_anti',
      name: 'product.product-main-types.relief_and_anti',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'relief_valves',
      name: 'product.product-main-types.relief_valves',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'relief_valves_bi',
      name: 'product.product-main-types.relief_valves_bi',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'sequence_valves',
      name: 'product.product-main-types.sequence_valves',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'shuttle_valves',
      name: 'product.product-main-types.shuttle_valves',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'solenoid_valves',
      name: 'product.product-main-types.solenoid_valves',
      categ: 'valves',
    });

    mainTypes.push({
      code: 'propreg',
      name: 'product.product-main-types.propreg',
      categ: 'propreg',
    });

    mainTypes.push({
      code: 'solconn',
      name: 'product.product-main-types.solconn',
      categ: 'solconn',
    });

    mainTypes.push({
      code: 'coils',
      name: 'product.product-main-types.coils',
      categ: 'coils',
    });

    mainTypes.push({
      code: 'coilconn',
      name: 'product.product-main-types.coilconn',
      categ: 'coilconn',
    });

    mainTypes.push({
      code: 'solvlvmov',
      name: 'product.product-main-types.solvlvmov',
      categ: 'solvlvmov',
    });

    mainTypes.push({
      code: 'coinkit',
      name: 'product.product-main-types.coinkit',
      categ: 'coinkit',
    });

    mainTypes.push({
      code: 'plstmpprfc',
      name: 'product.product-main-types.plstmpprfc',
      categ: 'plstmpprfc',
    });

    mainTypes.push({
      code: 'flowadj',
      name: 'product.product-main-types.flowadj',
      categ: 'flowadj',
    });

    mainTypes.push({
      code: 'adjknob',
      name: 'product.product-main-types.adjknob',
      categ: 'adjknob',
    });

    mainTypes.push({
      code: 'cavplugs',
      name: 'product.product-main-types.cavplugs',
      categ: 'cavplugs',
    });

    mainTypes.push({
      code: 'cavities',
      name: 'product.product-main-types.cavities',
      categ: 'cavities',
    });

    return mainTypes;
  }

  getValveGridCustomStore(category: string | null): CustomStore {
    return new CustomStore({
      key: 'id', // revision
      load: (loadOptions) => {
        if (loadOptions.userData) {
          loadOptions.userData.id_lang = this.currentLang;
          loadOptions.userData.category = category;
        }
        return this.gridCsSvc.loadStoreData<ValveGridData>(
          this.epSvc.getApiEndpoint('valve/list'),
          loadOptions
        );
      },
    });
  }
  getValveCatalogGridCustomStore(): CustomStore {
    return new CustomStore({
      key: 'id', // revision
      load: (loadOptions) => {
        loadOptions.userData.id_lang = this.currentLang;
        return this.gridCsSvc.loadStoreData<ValveCatalogGrid>(
          this.epSvc.getApiEndpoint('valve/catalog-list'),
          loadOptions
        );
      },
      update: (key, values) => {
        return new Promise((resolve, reject) => {
          if (!(values.code || values.catalogPageNumber || values.rev)) {
            Object.keys(values).forEach((value) => {
              this.http
                .patch<ApiResponse>(this.epSvc.getApiEndpoint('valve'), {
                  id: key,
                  column: value,
                  value: values[value as keyof ValveCatalogGrid],
                  type: UpdateValueType.INTEGER,
                })
                .pipe(take(1))
                .subscribe((response) => {
                  if (response.status == 'SUCCESS') {
                    resolve(response.payload);
                  } else {
                    reject();
                    this.logger.debug(response);
                  }
                });
            });
          } else {
            reject('You cannot edit this field');
          }
        });
      },
    });
  }

  getValveWebGridCustomStore(
    idCallback: GetValveLangIdCallback,
    codeCallback: GetValveCodeCallback,
    categCallback: GetValveCategCallback,
    typeIdCallback: GetValveTypeIdCallback
  ): CustomStore {
    return new CustomStore({
      key: 'id', // revision
      load: (loadOptions) => {
        loadOptions.userData['id_lang'] = idCallback();
        loadOptions.userData['code'] = codeCallback();
        loadOptions.userData['category'] = categCallback();
        loadOptions.userData['idType'] = typeIdCallback();

        return this.gridCsSvc.loadStoreData<ValveWebGridData>(
          this.epSvc.getApiEndpoint('valve/web-list'),
          loadOptions
        );
      },
    });
  }

  getValveRevisionGridCustomStore(
    valveIdCallback: GetValveIdCallback
  ): CustomStore {
    return new CustomStore({
      key: 'id', // revision
      load: (loadOptions: any) => {
        loadOptions.userData['id_valve'] = valveIdCallback();
        return this.gridCsSvc.loadStoreData<ValveRevision>(
          this.epSvc.getApiEndpoint('valve/revision/list'),
          loadOptions
        );
      },
      update: (key, values) => {
        return this.http
          .patch<ApiResponse>(this.epSvc.getApiEndpoint('valve/revision'), {
            id: key,
            column: Object.keys(values)[0],
            value: Object.values(values)[0],
            type: UpdateValueType.STRING,
          })
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
      remove: (key) => {
        return this.http
          .delete<ApiResponse>(
            this.epSvc.getApiEndpoint('valve/revision/' + key)
          )
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
    });
  }

  updateChartData(data: ValveChart) {
    return this.http.post<ApiResponse>(
      this.epSvc.getApiEndpoint('valve/chart/edit/data'),
      data
    );
  }

  getCorrelatedPosts(idRevision: number, code: string, lang: number) {
    return this.http.get<ApiResponse>(
      this.epSvc.getApiEndpoint(`/valve/correlated/${idRevision}/${code}`),
      { params: { id_lang: lang } }
    );
  }

  loadCharts(idRevision: number) {
    return this.http.post<ApiResponse>(
      this.epSvc.getApiEndpoint('valve/chart/list'),
      {
        userData: {
          id_revision: idRevision,
        },
      }
    );
  }

  getValveCurveGridCustomStore(
    getValveRevisionId: GetValveRevisionIdCallback,
    loadedCallback?: LoadedCallback
  ): CustomStore {
    return new CustomStore({
      key: 'id', // revision
      load: (loadOptions: any) => {
        loadOptions.userData['id_revision'] = getValveRevisionId();
        return this.gridCsSvc.loadStoreData<ValveChart>(
          this.epSvc.getApiEndpoint('valve/chart/list'),
          loadOptions
        );
      },
      update: (key, values) => {
        return this.http
          .patch<ApiResponse>(this.epSvc.getApiEndpoint('valve/chart'), {
            id: key,
            column: Object.keys(values)[0],
            value: Object.values(values)[0],
            type: UpdateValueType.STRING,
          })
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
      remove: (key) => {
        return this.http
          .delete<ApiResponse>(this.epSvc.getApiEndpoint('valve/chart/' + key))
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response?.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
      onLoaded: (result) => {
        if (loadedCallback) {
          loadedCallback(result);
        }
      },
    });
  }

  getValveBiasOptionsGridCustomStore(
    getValveRevisionId: GetValveRevisionIdCallback
  ): CustomStore {
    return new CustomStore({
      key: 'id', // revision
      load: (loadOptions: any) => {
        loadOptions.userData['id_revision'] = getValveRevisionId();
        return this.gridCsSvc.loadStoreData<BiasOption>(
          this.epSvc.getApiEndpoint('valve/bias-option/list'),
          loadOptions
        );
      },
      insert: (values) => {
        values.idRevision = getValveRevisionId();

        return this.http
          .post<ApiResponse>(
            this.epSvc.getApiEndpoint('valve/bias-option'),
            values
          )
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
      update: (key, values) => {
        console.log(key, values);
        const type = UpdateValueType.STRING;
        return new Promise((resolve, reject) => {
          Object.keys(values).forEach((element, i) => {
            console.log(element);
            this.http
              .patch<ApiResponse>(
                this.epSvc.getApiEndpoint('valve/bias-option'),
                {
                  id: key,
                  column: element,
                  value: Object.values(values)[i],
                  type: type,
                }
              )
              .toPromise()
              .then((response) => {
                if (response?.status == 'SUCCESS') {
                  return response.payload;
                } else {
                  this.logger.debug(response);
                  throw Error(response?.payload.errorDetails);
                }
              })
              .catch((error) => {
                this.logger.error(error);
                throw error.error.payload.errorDetails;
              });
          });
          resolve(null);
        });
      },
      remove: (key) => {
        return this.http
          .delete<ApiResponse>(
            this.epSvc.getApiEndpoint('valve/bias-option/' + key)
          )
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
    });
  }

  getCustomStore<T extends IBaseOptions>(
    endpoint: string,
    getValveRevisionId: GetValveRevisionIdCallback,
    getValveLangId: GetValveLangIdCallback
  ): CustomStore {
    return new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const idRev = getValveRevisionId();
        loadOptions.userData['id_revision'] = idRev;

        const langId = getValveLangId();
        if (langId > 0) {
          loadOptions.userData['id_lang'] = langId;
        }

        return this.gridCsSvc.loadStoreData<T>(
          this.epSvc.getApiEndpoint(endpoint + '/list'),
          loadOptions
        );
      },
      insert: (values) => {
        values.idRevision = getValveRevisionId();

        const langId = getValveLangId();
        if (langId > 0) {
          values.idLang = langId;
        }

        return this.http
          .post<ApiResponse>(this.epSvc.getApiEndpoint(endpoint), values)
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
      update: (key, values) => {
        const col = Object.keys(values)[0];
        let type = UpdateValueType.STRING;

        const val = Object.values(values)[0];
        if (typeof val == 'boolean' || val === 'true' || val === 'false') {
          type = UpdateValueType.BOOLEAN;
        }
        if (typeof val == 'number') {
          type = UpdateValueType.DOUBLE;
        }

        return this.http
          .patch<ApiResponse>(this.epSvc.getApiEndpoint(endpoint), {
            id: key,
            column: col,
            value: val,
            type: type,
          })
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
      remove: (key) => {
        return this.http
          .delete<ApiResponse>(this.epSvc.getApiEndpoint(endpoint + '/' + key))
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
    });
  }

  getValveFixedFlowSettingsGridCustomStore(
    getValveRevisionId: GetValveRevisionIdCallback
  ): CustomStore {
    return new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        loadOptions.userData['id_revision'] = getValveRevisionId();
        return this.gridCsSvc.loadStoreData<FixedFlowSettings>(
          this.epSvc.getApiEndpoint('valve/fixed-flow-settings/list'),
          loadOptions
        );
      },
      insert: (values) => {
        values.idRevision = getValveRevisionId();

        return this.http
          .post<ApiResponse>(
            this.epSvc.getApiEndpoint('valve/fixed-flow-settings'),
            values
          )
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
      update: (key, values) => {
        return this.http
          .patch<ApiResponse>(
            this.epSvc.getApiEndpoint('valve/fixed-flow-settings'),
            {
              id: key,
              column: '',
              value: JSON.stringify(values),
              type: UpdateValueType.STRING,
            }
          )
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
      remove: (key) => {
        return this.http
          .delete<ApiResponse>(
            this.epSvc.getApiEndpoint('valve/fixed-flow-settings/' + key)
          )
          .toPromise()
          .then((response) => {
            if (response?.status == 'SUCCESS') {
              return response.payload;
            } else {
              this.logger.debug(response);
              throw Error(response?.payload.errorDetails);
            }
          })
          .catch((error) => {
            this.logger.error(error);
            throw error.error.payload.errorDetails;
          });
      },
    });
  }

  /*getFontTablesDataByIdRevision(id:number,concatEndpoint:string): Observable<BiasOption[]> {
    return this.http.post<BiasOption[]>(this.epSvc.getApiEndpoint('valve/'+concatEndpoint+'/list'), {
      userData: {
        id_revision: id,
        id_lang:this.langState.defaultLanguage
      },
    });
  }*/

  getFontTablesDataByIdRevision(
    valveRevisionId: number
  ): Observable<GraphQLResponse<any>> {
    return this.apollo
      .watchQuery<{ valveRevision: any }>({
        query: ProductQueries.ORDERING_CODE(valveRevisionId),
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((response) => {
          return {
            loading: response.loading,
            error: response.error,
            data: response.data.valveRevision,
          };
        })
      );
  }

  sendQuoteEmail(data: QuoteMailModel, token: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.epSvc.getApiEndpoint('mail/quote'),
      {
        ...data,
        recaptcha: token,
      }
    );
  }

  searchProducts(queryFilter: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.epSvc.getApiEndpoint('valve/search'),
      {
        params: { q: queryFilter, id_lang: this.currentLang },
      }
    );
  }

  getFlowList(
    categ: string,
    pressure: number | undefined,
    solenoidType: number | undefined
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.epSvc.getApiEndpoint('valve/flow'), {
      params: this.createHttpParams({
        categ,
        pressure: pressure,
        solenoid_type: solenoidType,
      }),
    });
  }

  getPressureList(
    categ: string,
    flow: number | undefined,
    solenoidType: number | undefined
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.epSvc.getApiEndpoint('valve/pressure'),
      {
        params: this.createHttpParams({
          categ,
          flow: flow,
          solenoid_type: solenoidType,
        }),
      }
    );
  }

  changeValvePosition(
    idValve: number,
    newIndex: number
  ): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(
      this.epSvc.getApiEndpoint(
        `valve/change-position?id_valve=${idValve}&new_index=${newIndex}`
      ),
      {}
    );
  }

  changeCatalogPosition(
    idValve: number,
    newIndex: number
  ): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(
      this.epSvc.getApiEndpoint(
        `valve/catalog/change-position?id_valve=${idValve}&new_index=${newIndex}`
      ),
      {}
    );
  }

  private createHttpParams(params: any): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    Object.keys(params).forEach((param) => {
      if (params[param]) {
        httpParams = httpParams.set(param, params[param]);
      }
    });
    return httpParams;
  }

  getCoilRevisionIdByPower(power: number): Observable<IProductReference> {
    return this.http
      .get<ApiResponse>(this.epSvc.getApiEndpoint('valve/coil-reference'), {
        params: { coil_power: power },
      })
      .pipe(
        map((response) => {
          if (response.status === 'SUCCESS') {
            return response.payload;
          }
        })
      );
  }

  generateCatalog(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.epSvc.getApiEndpoint('valve/generate-catalog'),
      {
        params: {
          lang_id: this.currentLang,
        },
      }
    );
  }

  getProductVerification(idValve: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.epSvc.getApiEndpoint(`valve/${idValve}/verification`)
    );
  }

  updateProductVerification(
    idValve: number,
    verification: IUpdateVerification
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.epSvc.getApiEndpoint(`valve/${idValve}/verification`),
      verification
    );
  }

  deleteProductVerification(
    id: number,
    idValve: number
  ): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.epSvc.getApiEndpoint(`valve/${idValve}/verification/${id}`)
    );
  }
}
