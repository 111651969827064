import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EndpointService {
  /**
   * Compose API endpoint
   */
  getApiEndpoint(path: string): string {
    //const host = typeof (SERVER_HOSTNAME) !== 'undefined' && SERVER_HOSTNAME ? SERVER_HOSTNAME : window.location.hostname;
    const apiFullPath = path.startsWith('/') ? path : '/' + path;

    //const sPort = typeof (SERVER_PORT) !== 'undefined' && SERVER_PORT ? SERVER_PORT : 8080;
    return environment.backend_endpoint + apiFullPath;
  }
}
