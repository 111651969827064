import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScreenService } from 'src/app/core/services';
import { RouterUtilService } from 'src/app/core/services/router-util.service';

@Component({
  selector: 'app-website-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class WebsiteLayoutComponent implements OnInit, OnDestroy {
  location = '/';
  isMobile: boolean = this.scrnSvc.isMobile;

  private ngUnsubscribe = new Subject<void>();
  constructor(
    private routerUtilSvc: RouterUtilService,
    private scrnSvc: ScreenService
  ) {
    scrnSvc.changed.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.isMobile = scrnSvc.isMobile;
    });
  }

  ngOnInit(): void {
    this.routerUtilSvc.location$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.location = location.pathname;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  pathMatch() {
    return ['article', 'past-events', 'news', 'csr', 'welfare'].some((path) =>
      this.location.includes(path)
    );
  }
}
