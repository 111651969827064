import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DxTextBoxComponent } from 'devextreme-angular';
import { LangState } from 'src/app/core/state';

@Component({
  selector: 'app-website-right-toolbar',
  templateUrl: './right-toolbar.component.html',
  styleUrls: ['./right-toolbar.component.scss'],
})
export class WebsiteRightToolbarComponent {
  @ViewChild(DxTextBoxComponent, { static: false }) searchBox:
    | DxTextBoxComponent
    | undefined;
  showSearch = false;
  showLangList = false;

  selectedLangImg = '/assets/flags/united-kingdom.png';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private langState: LangState,
    private transloco: TranslocoService,
    private router: Router
  ) {}

  toggleSearch(e: MouseEvent) {
    this.showSearch = !this.showSearch;

    if (this.showSearch) {
      this.changeDetectorRef.detectChanges();

      if (this.searchBox) {
        this.searchBox.instance.focus();
      }
    }
  }

  onSearchKeyUp(e: any) {
    if (e.event.key === 'Escape') {
      if (this.searchBox) {
        this.searchBox.instance.option('text', '');
        this.searchBox.instance.blur();
      }

      this.showSearch = false;
    }
  }

  onValueChanged(e: any) {
    if (e.value != '') {
      this.router.navigate([`/search`], { queryParams: { q: e.value } });
    }
  }

  toggleLang(e: MouseEvent) {
    this.showLangList = !this.showLangList;
  }

  changeLang(e: MouseEvent, idLang: number) {
    switch (idLang) {
      case 1:
        this.selectedLangImg = '/assets/flags/united-kingdom.png';
        this.transloco.setActiveLang('en');
        break;
      case 2:
        this.selectedLangImg = '/assets/flags/united-kingdom.png';
        break;
      case 3:
        this.selectedLangImg = '/assets/flags/china.png';
        this.transloco.setActiveLang('cn');
        break;
      case 4:
        this.selectedLangImg = '/assets/flags/russia.png';
        this.transloco.setActiveLang('ru');
        break;
      case 5:
        this.selectedLangImg = '/assets/flags/united-kingdom.png';
        break;
      case 6:
        this.selectedLangImg = '/assets/flags/united-kingdom.png';
        break;
    }
    this.langState.selectLang(idLang);
  }
}
