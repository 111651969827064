import {NgModule, Optional, SkipSelf} from "@angular/core";
import {throwIfAlreadyLoaded} from './core-utils';
import {DxButtonModule} from "devextreme-angular/ui/button";
import {DxToolbarModule} from "devextreme-angular/ui/toolbar";
import {DxListModule} from "devextreme-angular/ui/list";
import {DxContextMenuModule} from "devextreme-angular/ui/context-menu";
import {DxDrawerModule} from "devextreme-angular/ui/drawer";
import {DxScrollViewModule} from "devextreme-angular/ui/scroll-view";
import {CommonModule} from "@angular/common";
import {DxTreeViewModule} from "devextreme-angular/ui/tree-view";
import {SharedModule} from "../shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxToolbarModule,
    DxListModule,
    DxContextMenuModule,
    DxDrawerModule,
    DxToolbarModule,
    DxScrollViewModule,
    DxTreeViewModule,
    SharedModule
  ]
})
export class CoreModule {
  public constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
