import { NgxsDataPluginModule } from '@angular-ru/ngxs';
import { NGXS_DATA_STORAGE_PLUGIN } from '@angular-ru/ngxs/storage';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InMemoryCache } from '@apollo/client/core';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { ToastrModule } from 'ngx-toastr';
import { HttpInterceptorService } from 'src/app/core/interceptors';
import { AuthState, LangState } from 'src/app/core/state';
import { ProductState } from 'src/app/core/state/product.state';
import { LayoutModule } from 'src/app/layout/layout.module';
import { HomeModule } from 'src/app/website/home/home.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TranslocoRootModule } from './transloco-root.module';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.production ? 'vishydraulics.com' : 'localhost',
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#03a9f4',
    },
  },
  position: 'bottom-right',
  theme: 'classic',
  type: 'opt-out',
  content: {
    message:
      "Sul nostro sito utilizziamo cookies tecnici e, previo consenso dell'utente, cookie di profilazione e di Terze Parti per inviare comunicazioni pubblicitarie personalizzate. La chiusura del banner attraverso l'utilizzo del tasto rifiuta o attraverso la X non ci consentirà l'utilizzo di cookie o altri strumenti di tracciamento diversi dai tecnici. Non sarà possibile per noi personalizzare la tua esperienza di navigazione. Consulta l'informativa estesa per avere maggiori informazioni <a href='/cookie-policy'>Cookie Policy</a>. Puoi accettare tutti i cookie cliccando il pulsante “Acconsento”e potrai comunque anche in seguito modificare le tue preferenze.",
    link: 'Cookie policy',
    href: 'https://www.vishydraulics.com/cookie-policy',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    ApolloModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslocoRootModule,
    AppRoutingModule,
    NgxsModule.forRoot([AuthState, LangState, ProductState]),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsDataPluginModule.forRoot(NGXS_DATA_STORAGE_PLUGIN),
    ToastrModule.forRoot(),
    LoggerModule.forRoot({
      level: environment.production
        ? NgxLoggerLevel.ERROR
        : NgxLoggerLevel.DEBUG,
      serverLogLevel: environment.production
        ? NgxLoggerLevel.ERROR
        : NgxLoggerLevel.DEBUG,
    }),
    NgxScrollTopModule,
    CoreModule,
    LayoutModule,
    HomeModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphql_endpoint,
          }),
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class AppModule {}
