import {Component, HostBinding} from '@angular/core';
import {ScreenService} from "src/app/core/services";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class AdminLayoutComponent {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  copyright = "";

  constructor(private screen: ScreenService) {
    const currentYear = new Date().getFullYear();
    this.copyright = (currentYear > 2021 ? '2021-'+currentYear :''+currentYear) + " Vis Hydraulics S.r.l.";
  }
}
