<ng-container *transloco="let t">
  <div class="product-configurator" [ngClass]="{ mobile: isMobile }">
    <h4 class="vis-title">{{ t("website.select_product_family") }}</h4>
    <div
      [fxLayout]="isMobile ? 'column' : 'row'"
      fxLayoutGap="15px"
      fxLayoutAlign="center center"
    >
      <dx-select-box
        [elementAttr]="{ class: 'product-family-selector input-padding' }"
        labelMode="hidden"
        stylingMode="outlined"
        [placeholder]="t('product.select_product_family')"
        [items]="mainTypes"
        displayExpr="name"
        valueExpr="code"
        (onSelectionChanged)="prodMainTypesSelected($event)"
        fieldTemplate="field"
      >
        <div *dxTemplate="let data of 'field'">
          <dx-text-box
            class="product-name"
            [value]="
              data && data.name
                ? t(data.name)
                : t('product.select_product_family')
            "
            [readOnly]="true"
          ></dx-text-box>
        </div>
        <div *dxTemplate="let data of 'item'">
          {{ t(data.name) }}
        </div>
        <!--<div *dxTemplate="let data of 'dropDownButton'">
              <svg
                xmlns="htt://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="select-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
            <div *dxTemplate="let data of 'item'">
              {{ t(data.name) }}
            </div>-->
      </dx-select-box>
      <div
        [fxLayout]="isMobile ? 'column' : 'row'"
        *ngIf="selectedProdMainType"
        fxLayoutGap="20"
      >
        <dx-text-box
          class="config-input"
          placeholder="Description"
          stylingMode="outlined"
          (onValueChanged)="subtitleFilterChange($event)"
          *ngIf="selectedProdMainType.code !== 'solenoid_valves'"
        ></dx-text-box>
        <dx-select-box
          [items]="availableGroupTypes"
          [elementAttr]="{ class: 'product-family-selector input-padding' }"
          labelMode="hidden"
          displayExpr="value"
          valueExpr="code"
          stylingMode="outlined"
          [width]="isMobile ? 300 : 400"
          (onSelectionChanged)="groupTypeChange($event)"
          fieldTemplate="field"
          *ngIf="selectedProdMainType.code === 'solenoid_valves'"
        >
          <div *dxTemplate="let data of 'field'">
            <dx-text-box
              class="product-name"
              [value]="
                data && data.value ? data.value : t('product.group_type')
              "
              [readOnly]="true"
            >
            </dx-text-box>
          </div>
          <div *dxTemplate="let data of 'item'">
            {{ data.value }}
          </div>
        </dx-select-box>
        <dx-select-box
          [items]="availableFlows"
          [elementAttr]="{ class: 'product-family-selector' }"
          labelMode="hidden"
          stylingMode="outlined"
          (onSelectionChanged)="flowChange($event)"
          [searchEnabled]="true"
          [placeholder]="t('product.chart_flow')"
          searchMode="startswith"
        >
        </dx-select-box>
        <dx-select-box
          [items]="availablePressures"
          [elementAttr]="{ class: 'product-family-selector' }"
          labelMode="hidden"
          stylingMode="outlined"
          [placeholder]="t('product.chart_pressure')"
          (onSelectionChanged)="pressureChange($event)"
          [searchEnabled]="true"
          searchMode="startswith"
        >
        </dx-select-box>
        <dx-button
          icon="chevronnext"
          [elementAttr]="{ class: 'product-family-selector-btn' }"
          [hint]="t('product.goto_product')"
          (onClick)="gotoSelectedType()"
          fxFlexAlign="center"
        ></dx-button>
      </div>
    </div>
  </div>
</ng-container>
