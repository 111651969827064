import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterUtilService {
  private _show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private _location$: BehaviorSubject<string> = new BehaviorSubject<string>(
    '/'
  );

  get show$(): Observable<boolean> {
    return this._show$.asObservable();
  }

  get location$(): Observable<string> {
    return this._location$.asObservable();
  }

  getCurrentLocation(): string {
    return this._location$.getValue();
  }

  /**
   * Show the loading bar
   */
  show(): void {
    this._show$.next(true);
  }

  /**
   * Hide the loading bar
   */
  hide(): void {
    this._show$.next(false);
  }

  /**
   * Hide the loading bar
   */
  setLocation(location: string): void {
    this._location$.next(location);
  }
}
