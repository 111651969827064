<ng-container *transloco="let t">
  <div fxLayout="row" *ngIf="!isMobile" [ngClass]="{
      header: true,
      home: location === '/',
      secondary: secondary,
      mobile: isMobile,
      tablet: isTablet
    }">
    <div class="header-container" fxLayout="row" fxLayoutAlign="center center">
      <a routerLink="/">
        <img class="logo" [src]="
            !secondary
              ? 'assets/images/logo_full.png'
              : 'assets/images/logo_full_white.png'
          " /></a>
      <div fxFlex *ngIf="!isMobile"></div>
      <div class="main-menu">
        <app-website-main-menu [secondary]="secondary"></app-website-main-menu>
      </div>
    </div>
  </div>

  <dx-drawer *ngIf="isMobile" class="drawer-mobile mobile" position="before" [closeOnOutsideClick]="shaderEnabled"
    [openedStateMode]="menuMode" [revealMode]="menuRevealMode" [minSize]="minMenuSize" [shading]="shaderEnabled"
    [(opened)]="mobileDrawerOpened" template="panel" [closeOnOutsideClick]="true">
    <div *dxTemplate="let item of 'panel'">
      <div class="drawer-panel">
        <div class="drawer-header" fxLayout="row" fxLayoutAlign="space-between center">
          <a routerLink="/" (click)="toggleMenu()">
            <img src="/assets/images/logo_full.png" />
          </a>
          <i class="icon-clear small" (click)="toggleMenu()"></i>
        </div>
        <app-website-main-menu [secondary]="secondary" (toggleMenu)="mobileDrawerOpened = false"
          [mobile]="true"></app-website-main-menu>
        <div class="mobile-menu-footer" fxLayout="row" fxLayoutAlign="space-evenly center">
          <a><i class="icon-location small"></i></a>
          <a href="https://www.linkedin.com/company/vis-hydraulics/" target="_blank"><i
              class="icon-linkedin-square small"></i></a>
          <a href="https://it-it.facebook.com/vishydraulics/" target="_blank"><i
              class="icon-facebook-square small"></i></a>
        </div>
      </div>
    </div>
    <div class="container">
      <dx-scroll-view class="layout-body with-footer">
        <dx-toolbar class="mobile-toolbar">
          <dxi-item location="before" widget="dxButton" cssClass="menu-hamburger" [options]="{
              icon: 'menu',
              onClick: toggleMenu
            }">
          </dxi-item>
          <dxi-item location="after">
            <ul>
              <li (click)="toggleLang($event)" [title]="t('change_language')" fxLayout="row" *ngIf="false">
                <img src="/assets/flags/united-kingdom.png" class="selected-lang" />
                <div *ngIf="showLangList" class="lang-submenu">
                  <ul fxLayout="row-reverse">
                    <li class="disabled">
                      <img src="/assets/flags/italy.png" />
                    </li>
                    <li>
                      <img src="/assets/flags/united-kingdom.png" />
                    </li>
                    <li class="disabled">
                      <img src="/assets/flags/china.png" />
                    </li>
                    <li class="disabled">
                      <img src="/assets/flags/russia.png" />
                    </li>
                    <li class="disabled">
                      <img src="/assets/flags/spain.png" />
                    </li>
                    <li class="disabled">
                      <img src="/assets/flags/turkey.png" />
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </dxi-item>
          <dxi-item location="after" *ngIf="false">
            <i class="icon-search small"></i>
          </dxi-item>
        </dx-toolbar>
        <div class="content">
          <ng-content></ng-content>
        </div>
      </dx-scroll-view>
    </div>
  </dx-drawer>
</ng-container>