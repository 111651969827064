import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LoadOptions } from 'devextreme/data';
import { NGXLogger } from 'ngx-logger';
import { ApiResponse, GridResponseModel } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class GridCustomStoreService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private transloco: TranslocoService
  ) {}

  loadStoreData<T>(
    url: string,
    loadOptions: LoadOptions<any>
  ): Promise<GridResponseModel<T>> {
    this.logger.debug('loadOptions: %O', loadOptions);

    return this.http
      .post<ApiResponse>(url, {
        filter: loadOptions && loadOptions.filter ? loadOptions.filter : '',
        group: loadOptions && loadOptions.group ? loadOptions.group : '',
        groupSummary:
          loadOptions && loadOptions.groupSummary
            ? loadOptions.groupSummary
            : '',
        requireGroupCount: loadOptions
          ? loadOptions.requireGroupCount
          : 'false',
        requireTotalCount: loadOptions
          ? loadOptions.requireTotalCount
          : 'false',
        select: loadOptions && loadOptions.select ? loadOptions.select : '',
        skip: loadOptions ? loadOptions.skip : '',
        sort: loadOptions && loadOptions.sort ? loadOptions.sort : '',
        take: loadOptions ? loadOptions.take : '',
        totalSummary:
          loadOptions && loadOptions.totalSummary
            ? loadOptions.totalSummary
            : '',
        userData:
          loadOptions && loadOptions.userData ? loadOptions.userData : '',
      })
      .toPromise()
      .then((response) => {
        this.logger.debug('customStoreResponse', response);
        if (response?.status == 'SUCCESS') {
          return response.payload as GridResponseModel<T>;
        } else {
          this.logger.debug('customStoreResponse failure', response);
          throw Error(
            this.transloco.translate('errors.cannot_load_data') +
              ': ' +
              response?.payload
          );
        }
      })
      .catch((error) => {
        this.logger.error('customStoreError', error);
        if (error.error.payload) {
          if (error.error.payload.errorDetails) {
            throw Error(
              this.transloco.translate('errors.cannot_load_data') +
                ': ' +
                error.error.payload.errorDetails
            );
          } else {
            throw Error(
              this.transloco.translate('errors.cannot_load_data') +
                ': ' +
                error.error.payload
            );
          }
        } else {
          throw error.error.message;
        }
      });
  }
}
