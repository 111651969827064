export interface ValvePictures {
  id: number | null;
  idRevision: number | null;
  auditUser: string | null;
  symbolFile: string | null;
  tredModelFile: string | null;
  crossSectionFile: string | null;
  crossSectionDetailA: number | null;
  crossSectionDetailB: number | null;
  crossSectionDetailC: number | null;
  crossSectionDetailD: number | null;
  cavityDetailsFile: string | null;
  cavityDetailsFile2: string | null;
  designFile: string | null;
  externalDimensionsFile: string | null;
  circuitsFile: string | null;
  cavityDetailX: number | null;
  cavityDetailY: number | null;
  cavityDetailZ: number | null;
  cavityDetailW: number | null;
  cavityName: string | null;
  cavityName2: string | null;
  stepFile: string | null;
}

export const emptyValvePictures = {
  id: null,
  idRevision: null,
  auditUser: null,
  symbolFile: null,
  tredModelFile: null,
  crossSectionFile: null,
  designFile: null,
  externalDimensionsFile: null,
  circuitsFile: null,
  crossSectionDetailA: null,
  crossSectionDetailB: null,
  crossSectionDetailC: null,
  crossSectionDetailD: null,
  cavityDetailsFile: null,
  cavityDetailsFile2: null,
  cavityDetailX: null,
  cavityDetailY: null,
  cavityDetailZ: null,
  cavityDetailW: null,
  cavityName: null,
  cavityName2: null,
  stepFile: null,
};
