import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DxScrollViewComponent} from 'devextreme-angular/ui/scroll-view';
import {NavigationEnd, Router} from '@angular/router';
import {ItemClickEvent as ToolbarItemClickEvent} from 'devextreme/ui/toolbar';
import {ItemClickEvent as TreeViewItemClickEvent} from 'devextreme/ui/tree_view';
import {NGXLogger} from 'ngx-logger';
import {MainMenuItem} from 'src/app/core/models';
import {ScreenService} from 'src/app/core/services';
import { OpenedStateMode, RevealMode } from 'devextreme/ui/drawer';

@Component({
  selector: 'app-admin-side-nav-inner-toolbar',
  templateUrl: './side-nav-inner-toolbar.component.html',
  styleUrls: ['./side-nav-inner-toolbar.component.scss'],
})
export class AdminSideNavInnerToolbarComponent implements OnInit {
  @ViewChild(DxScrollViewComponent, { static: true })
  scrollView!: DxScrollViewComponent;
  selectedRoute = '';

  menuOpened!: boolean;
  temporaryMenuOpened = false;

  isMobile: boolean = this.scrnSvc.isMobile;

  @Input()
  title!: string;

  menuMode: OpenedStateMode = 'shrink';
  menuRevealMode: RevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;

  constructor(
    private screen: ScreenService,
    private router: Router,
    private logger: NGXLogger,
    private scrnSvc: ScreenService
  ) {}

  ngOnInit() {
    this.menuOpened = this.screen.sizes['screen-large'];

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }

  updateDrawer() {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.minMenuSize = isXSmall ? 0 : 60;
    this.shaderEnabled = !isLarge;
  }

  toggleMenu = (e: ToolbarItemClickEvent) => {
    this.menuOpened = !this.menuOpened;
    e.event?.stopPropagation();
  };

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  navigationChanged(event: TreeViewItemClickEvent) {
    const data = <MainMenuItem>event.itemData;
    const path = data.path;
    const pointerEvent = event.event;

    this.logger.debug('MainMenu path', path);

    if (path && (this.menuOpened || this.isMobile)) {
      this.logger.debug('Menu navigation', path);
      this.router.navigate([path]);
      this.scrollView.instance.scrollTo(0);

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent?.stopPropagation();
      }
    } else {
      pointerEvent?.preventDefault();
    }
  }

  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }
}
