<div fxFlex="column" class="right-toolbar" *transloco="let t">
  <ul>
    <li [title]="t('click_to_search')" *ngIf="false">
      <i class="icon-search" (click)="toggleSearch($event)"></i>
      <div *ngIf="showSearch" class="submenu lang-submenu">
        <ul fxLayout="row-reverse">
          <li>
            <dx-text-box
              mode="search"
              labelMode="hidden"
              placeholder="Search"
              (onKeyUp)="onSearchKeyUp($event)"
              (onValueChanged)="onValueChanged($event)"
            ></dx-text-box>
          </li>
        </ul>
      </div>
    </li>
    <li
      (click)="toggleLang($event)"
      [title]="t('change_language')"
      *ngIf="false"
    >
      <img [src]="selectedLangImg" />
      <div *ngIf="showLangList" class="submenu lang-submenu">
        <ul fxLayout="row-reverse">
          <li *ngIf="false"><img src="/assets/flags/italy.png" /></li>
          <li (click)="changeLang($event, 1)">
            <img src="/assets/flags/united-kingdom.png" />
          </li>
          <li (click)="changeLang($event, 3)">
            <img src="/assets/flags/china.png" />
          </li>
          <li (click)="changeLang($event, 4)">
            <img src="/assets/flags/russia.png" />
          </li>
          <li *ngIf="false"><img src="/assets/flags/spain.png" /></li>
          <li *ngIf="false"><img src="/assets/flags/turkey.png" /></li>
        </ul>
      </div>
    </li>
    <li>
      <a href="mailto:info@vishydraulics.com"
        ><i class="icon-mail-envelope-closed"></i
      ></a>
    </li>
    <li>
      <a routerLink="/sales-distribution"
        ><i class="icon-location small"></i
      ></a>
    </li>
    <li>
      <a href="https://www.linkedin.com/company/vis-hydraulics/" target="_blank"
        ><i class="icon-linkedin-square small"></i
      ></a>
    </li>
    <li>
      <a href="https://it-it.facebook.com/vishydraulics/" target="_blank"
        ><i class="icon-facebook-square small"></i
      ></a>
    </li>
    <li style="display: none">
      <a><i class="icon-youtube-play small"></i></a>
    </li>
  </ul>
</div>
