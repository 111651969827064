<div class="menu-container" *transloco="let t; scope: 'menu'">
  <dx-tree-view
    [items]="items"
    keyExpr="path"
    selectionMode="single"
    [focusStateEnabled]="false"
    (onItemClick)="onItemClick($event)"
    width="100%"
  >
    <div *dxTemplate="let item of 'item'">
      <i
        *ngIf="item.icon"
        [class]="
          'dx-icon ' +
          (item.icon.startsWith('vis') ? item.icon : 'dx-icon-' + item.icon)
        "
      ></i>
      <span
        [ngClass]="{
          active:
            (item.path && activeRoute && item.path === activeRoute) ||
            (item.category && category && item.category === category)
        }"
        >{{ t(item.text) }}</span
      >
    </div>
  </dx-tree-view>
</div>
