import { ValveGroupOptions } from './valve-group-options.model';
import { ValveTypeOptions } from './valve_type_options.model';

export interface ValveType {
  id: number,
  name: string,
  options: ValveTypeOptions,
  groupOptions: ValveGroupOptions[] | null,
}

export const emptyTypeOptions: ValveTypeOptions = {
  fld_min_pul_vlt: false,
  fld_set_press: false,
  fld_press_set_rng: false,
  fld_ac_crack_pres: false,
  fld_press_set_est: false,
  fld_res_press: false,
  fld_tigh_torq_nut: false,
  fld_tigh_torq_blind_nut: false,
  fld_pl_tamp_proof_cap: false,
  fld_wire_seal_tamp_proof: false,
  fld_swi_prs: false,
  fld_max_xomp_byp: false,
  fld_max_comp_pri: false,
  fld_comp_spring_acc: false,
  fld_pilot_ratio: false,
  fld_coin_kit: false,
  fld_coil2: false,
  fld_inst_tool: false,
  fld_seal_kit: false,
  fld_inst_torque: false,
  fld_oring_temp_rng: false,
  fld_ext_comp_treat: false,
  fld_max_int_leak: false,
  fld_coil: false,
  fld_switch_on: false,
  fld_switch_off: false,
  tbl_bias_spring: false,
  tbl_cracking_pressure: false,
  tbl_switching_pressure: false,
  tbl_pressure_setting_range: false,
  fld_maximum_flow_thermal_relief_valve: false,
  fld_thermal_relief_crack_pressure_2_to_1: false,
  tbl_maximum_internal_leakage: false,
  tbl_pressure_increment_per_turn: false,
  tbl_frc: false,
  tbl_frs: false,
  tbl_frd: false,
  tbl_basic_options: false,
  tbl_spring_options: false,
  tbl_fixed_flow_setting: false,
  tbl_steel_wire_options: false,
  tbl_flow_setting_range: false,
  tbl_filtration: false,
  tbl_flow_path: false,
  tbl_flow_adjustment: false,
  tbl_manual_override: false,
  tbl_config_code_option: false,
};

export const emptyValveType: ValveType = {
  id: 0,
  name: '',
  options: emptyTypeOptions,
  groupOptions: null,
};
