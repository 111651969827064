export interface ValveRevision {
  id: number | null;
  idValve: number | null;
  auditUser: string | null;
  rev: string | null;
  year: number | null;
  revisionDate: string | null;
  description: string | null;
  status: string | null;
}

export const emptyRevision: ValveRevision = {
  id: null,
  idValve: null,
  auditUser: null,
  rev: null,
  year: null,
  revisionDate: null,
  description: null,
  status: null,
};
