import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";
import {NGXLogger} from "ngx-logger";
import {ToastrService} from "ngx-toastr";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ApiResponse, Lang} from "src/app/core/models";
import {EndpointService} from "src/app/core/services/endpoint.service";

@Injectable({
  providedIn: 'root'
})
export class LangService {

  constructor(private http: HttpClient, private epSvc: EndpointService, private toastr: ToastrService,
              private logger: NGXLogger, private transloco: TranslocoService) { }

  loadLanguages(): Observable<Lang[]> {
    return this.http.get<ApiResponse>(
      this.epSvc.getApiEndpoint('lang')
    ).pipe(map(response => {
      if(response.status === "SUCCESS") {
        return response.payload;
      } else {
        this.logger.error(response.payload);
        this.toastr.error(this.transloco.translate('errors.cannot_load_languages'));
      }
    }));
  }
}
