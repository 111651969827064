<header *transloco="let t">
  <dx-toolbar class="header-toolbar">
    <dxi-item [text]="t('language')+':'" location="after" cssClass="toolbar-label"></dxi-item>
    <dxi-item location="after">
      <app-language-selector></app-language-selector>
    </dxi-item>
    <dxi-item location="after" cssClass="toolbar-spacer"></dxi-item>
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }">
    </dxi-item>
    <dxi-item
      location="before"
      cssClass="header-title"
      *ngIf="title"
      [text]="title">
    </dxi-item>
    <dxi-item
      location="after"
      locateInMenu="auto"
      widget="dxDropDownButton"
      [options]="{
        text: user,
        width: '200px',
        icon: 'https://eu.ui-avatars.com/api/?rounded=true&name='+user,
        displayExpr: 'name',
        items: dropdownItems
      }"
    ></dxi-item>
    <!--<div *dxTemplate="let data of 'menuItem'">
      <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="list"></app-user-panel>
    </div>-->
  </dx-toolbar>
</header>
