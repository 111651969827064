import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { RouterUtilService } from 'src/app/core/services/router-util.service';
import { AuthState } from 'src/app/core/state';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private logger: NGXLogger,
    public authState: AuthState,
    private routerUtilSvc: RouterUtilService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let h: HttpHeaders = request.headers;

    if (this.authState.token && this.needBearer(request.url, request.method)) {
      this.logger.debug('Add bearer to ' + request.url);

      h = h.set('Authorization', 'Bearer ' + this.authState.token);
    }

    request = request.clone({ headers: h });

    return next.handle(request).pipe(
      catchError((error) => {
        this.logger.error(error);
        return throwError(error);
      })
    );
  }

  needBearer(url: string, method: string) {
    const curUrl = this.routerUtilSvc.getCurrentLocation();
    const isAdmin = curUrl.startsWith('/admin/');

    return (
      !url.endsWith('/auth/sign-in') &&
      !url.endsWith('/password-reset') &&
      !url.includes('/assets/') &&
      (!url.endsWith('/lang') || isAdmin) &&
      !url.includes('/valve/type-by-code') &&
      !url.endsWith('/valve/web-list') &&
      !url.endsWith('/graphql') &&
      !url.endsWith('/valve/chart/list') &&
      !(url.includes('valve') && url.endsWith('/types')) &&
      !url.includes('/front') &&
      !url.includes('bytype/') &&
      !url.includes('getrevision/') &&
      !url.includes('valve/correlated/') &&
      !url.includes('mail/') &&
      !url.includes('vavle/image') &&
      !url.includes('/plants') &&
      !url.includes('/story') &&
      !url.endsWith('valve/search') &&
      !(method === 'GET' && !url.includes('verification'))
    );
  }
}
