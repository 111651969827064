import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Data,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import config from 'devextreme/core/config';
import {
  NgcCookieConsentService,
  NgcInitializationErrorEvent,
  NgcInitializingEvent,
} from 'ngx-cookieconsent';
import { NGXLogger } from 'ngx-logger';
import { Subject, Subscription } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { RouterUtilService } from 'src/app/core/services/router-util.service';
import { LangState } from './core/state';
import { SeoService } from './core/services/seo.service';
import { httpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  langMap: any = {
    en: 1,
    cn: 3,
    ru: 4,
  };

  popupOpenSubscription!: Subscription;
  popupCloseSubscription!: Subscription;
  initializingSubscription!: Subscription;
  initializedSubscription!: Subscription;
  initializationErrorSubscription!: Subscription;
  statusChangeSubscription!: Subscription;

  constructor(
    private router: Router,
    private routerUtilSvc: RouterUtilService,
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private transloco: TranslocoService,
    private langState: LangState,
    private ccService: NgcCookieConsentService,
    private seo: SeoService
  ) {
    config({
      editorStylingMode: 'underlined',
    });

    if (environment.production && this.ccService.hasConsented()) {
      this.loadScript(
        'https://www.googletagmanager.com/gtag/js?id=UA-91172268-1'
      );
      this.loadScript(
        undefined,
        `window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "UA-91172268-1");`
      );
      Sentry.init({
        dsn: 'https://dbdde5c7b24dd4368fcdded4048a31f7@sentry.studioitc.com/9',
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({ maskAllText: false }),
          httpClientIntegration(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        sendDefaultPii: true,
      });
    }

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      //load analytics script
      if (this.ccService.hasConsented()) {
        this.ccService.close(false);
        window.location.reload();
      }
    });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event) => {
        if (event.status === 'deny') {
          window.location.reload();
        }
      }
    );

    this.initializingSubscription = this.ccService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        console.log(`initializing: ${JSON.stringify(event)}`);
      }
    );

    this.initializedSubscription = this.ccService.initialized$.subscribe(() => {
      console.log(`initialized`, this.ccService.hasConsented());
    });

    this.initializationErrorSubscription =
      this.ccService.initializationError$.subscribe(
        (event: NgcInitializationErrorEvent) => {
          console.log(
            `initializationError: ${JSON.stringify(event.error?.message)}`
          );
        }
      );
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        tap(({ title, description }: Data) => {
          if (title) {
            this.seo.updateTitle(title);
          }
          if (description) {
            this.seo.updateDescription(description);
          }
        })
      )
      .subscribe();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event) => {
        this.logger.debug('routerEvent', event);
        this.routerUtilSvc.show();
      });
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        ),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event) => {
        this.routerUtilSvc.setLocation(this.router.url);

        this.routerUtilSvc.hide();

        if (!(event instanceof NavigationEnd)) {
          return;
        }

        window.scrollTo(0, 0);
      });
    this.route.queryParams.subscribe((queryMap) => {
      if (queryMap.lang && this.langMap[queryMap.lang]) {
        this.langState.selectLang(this.langMap[queryMap.lang]);
        this.transloco.setActiveLang(queryMap.lang);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
  }

  private loadScript(
    url: string | undefined = undefined,
    text: string | undefined = undefined
  ) {
    const node = document.createElement('script');
    if (url) node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    if (text) node.text = text;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
