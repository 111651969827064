<ul
  [fxLayout]="mobile ? 'column' : 'row'"
  [ngClass]="mobile ? 'mobile-menu' : 'menu'"
  *ngIf="!secondary"
>
  <li>
    <a
      class="vis-title medium"
      [routerLink]="['/about-us']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >ABOUT US</a
    >
  </li>
  <li>
    <a
      class="vis-title medium"
      [routerLink]="['/product/list']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >PRODUCTS</a
    >
  </li>
  <li>
    <a
      class="vis-title medium"
      [routerLink]="['/applications']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >APPLICATIONS</a
    >
  </li>
  <li>
    <a
      class="vis-title medium"
      [routerLink]="['/production-technology']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >PRODUCTION TECHNOLOGY</a
    >
  </li>
  <li>
    <a
      class="vis-title medium"
      [routerLink]="['/innovation-center']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >INNOVATION CENTER</a
    >
  </li>
  <li>
    <a
      class="vis-title medium"
      [routerLink]="['/sales-distribution']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >SALES & DISTRIBUTION</a
    >
  </li>
  <li>
    <a
      class="vis-title medium"
      [routerLink]="['/download-area']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >DOWNLOAD AREA</a
    >
  </li>
  <li>
    <a
      class="vis-title medium"
      [routerLink]="['/job-opportunities']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >JOB OPPORTUNITIES</a
    >
  </li>
  <!--<li><a [routerLink]="['/admin']">ADMIN</a></li>-->
</ul>
<ul
  [fxLayout]="mobile ? 'column' : 'row'"
  [ngClass]="mobile ? 'mobile-menu' : 'menu'"
  *ngIf="secondary"
>
  <li>
    <a
      class="vis-title medium black"
      [routerLink]="['/news']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >NEWS</a
    >
  </li>
  <li>
    <a
      class="vis-title medium black"
      [routerLink]="['/past-events']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >EVENTS</a
    >
  </li>
  <li>
    <a
      class="vis-title medium black"
      [routerLink]="['/csr']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >CSR</a
    >
  </li>
  <li>
    <a
      class="vis-title medium black"
      [routerLink]="['/welfare']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >WELFARE ACTIVITIES</a
    >
  </li>
  <li>
    <a
      class="vis-title medium black"
      [routerLink]="['/innovation-center']"
      routerLinkActive="active"
      (click)="mobile ? toggleMenu.emit() : false"
      >SPONSORSHIP</a
    >
  </li>
</ul>
