import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RouterUtilService } from 'src/app/core/services/router-util.service';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(10, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class PageLoaderComponent implements OnInit, OnDestroy {
  show = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(private routerUtilSvc: RouterUtilService) {}

  ngOnInit(): void {
    this.routerUtilSvc.show$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.show = value;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
