import {
  Computed,
  DataAction,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Selector, State } from '@ngxs/store';
import { take } from 'rxjs/operators';
import {
  ApiResponse,
  AuthStateModel,
  SignInModel,
  SingInResponseModel,
} from 'src/app/core/models';

import { AuthService } from '../services';

@Persistence()
@StateRepository()
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    accessToken: null,
    refresh: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class AuthState extends NgxsDataRepository<AuthStateModel> {
  constructor(private readonly authSvc: AuthService, private router: Router) {
    super();
  }

  @Computed()
  public get token(): string | null {
    return this.snapshot.accessToken ? this.snapshot.accessToken.token : null;
  }

  @Computed()
  public get isAuthenticated(): boolean {
    const token = this.snapshot.accessToken?.token || null;
    const expireAt = this.snapshot.accessToken?.tokenExpiresAt || null;

    if (token && expireAt) {
      const expiresIn = expireAt - Math.ceil(new Date().getTime() / 1000);
      return expiresIn > 0;
    } else {
      return false;
    }
  }

  @Computed()
  public get isTokenExpired(): boolean {
    const token = this.snapshot.accessToken?.token || null;
    const expireAt = this.snapshot.accessToken?.tokenExpiresAt || null;

    if (token && expireAt) {
      const expiresIn = expireAt - Math.ceil(new Date().getTime() / 1000);
      return expiresIn < 0;
    } else {
      return false;
    }
  }

  @Computed()
  public get userInitials(): string {
    const token = this.snapshot.accessToken?.token || null;
    const userFullName = token ? this.authSvc.getTokenUserFullName(token) : '-';

    if (userFullName !== '-') {
      const nameArr = userFullName.split(/(\s+)/);
      if (nameArr.length >= 2) {
        return (
          nameArr[0].substring(0, 1).toUpperCase() +
          nameArr[1].substring(0, 1).toUpperCase()
        );
      } else {
        return userFullName.substring(0, 1).toUpperCase();
      }
    } else {
      return '-';
    }
  }

  @Computed()
  public get userFullName(): string {
    const token = this.snapshot.accessToken?.token || null;
    return token ? this.authSvc.getTokenUserFullName(token) : '';
  }

  @Selector()
  static token(state: AuthStateModel) {
    return state.accessToken?.token || null;
  }

  @Computed()
  public get userId(): number | null {
    const token = this.snapshot.accessToken?.token || null;
    return token ? this.authSvc.getTokenUserId(token) : null;
  }

  @DataAction()
  public signIn(signInData: SignInModel): void {
    this.authSvc
      .signIn(signInData.username, signInData.password)
      .pipe(take(1))
      .subscribe(
        (r: ApiResponse) => {
          if (r.status === 'SUCCESS') {
            const authResponse: SingInResponseModel = r.payload;

            this.patchState({
              accessToken: {
                token: authResponse.accessToken,
                tokenExpiresAt: authResponse.accessTokenExpiresAt,
              },
              refresh: {
                token: authResponse.refreshTokenHash,
                tokenExpiresAt: authResponse.refreshHashExpiresAt,
              },
            });

            this.ctx.dispatch({ type: 'AuthenticationSuccess' });
          } else {
            this.ctx.dispatch({ type: 'AuthenticationError' });
          }
        },
        (error) => {
          // Authentication error
          this.ctx.dispatch({ type: 'AuthenticationError' });
        }
      );
  }

  @DataAction()
  public updateToken(authToken: SingInResponseModel): void {
    this.patchState({
      accessToken: {
        token: authToken.accessToken,
        tokenExpiresAt: authToken.accessTokenExpiresAt,
      },
      refresh: {
        token: authToken.refreshTokenHash,
        tokenExpiresAt: authToken.refreshHashExpiresAt,
      },
    });
  }

  @DataAction()
  public signOut(): void {
    // Set current tenant
    const token = this.ctx.getState().accessToken;
    const refreshToken = this.ctx.getState().refresh;

    this.ctx.patchState({
      accessToken: null,
      refresh: null,
    });

    if (token && refreshToken) {
      const userId = this.authSvc.getTokenUserId(token.token);

      this.authSvc
        .signOut(userId)
        .pipe(take(1))
        .subscribe(() => {
          this.router.navigate(['/']);
        });
    } else {
      this.router.navigate(['/']);
    }
  }

  @DataAction()
  tokenExpired() {
    // Reset token
    this.ctx.patchState({
      accessToken: null,
      refresh: null,
    });

    this.router.navigate(['/sign-in'], { queryParams: { tokenExpired: true } });
  }
}
