import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import { ItemClickEvent } from 'devextreme/ui/tree_view';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainMenuItem } from 'src/app/core/models';
import { AuthService } from 'src/app/core/services';
import { RouterUtilService } from 'src/app/core/services/router-util.service';
import { AuthState } from 'src/app/core/state';
import { navigation } from 'src/app/layout/components/admin/app-navigation';

@Component({
  selector: 'app-admin-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss'],
})
export class AdminSideNavigationMenuComponent implements OnDestroy {
  @ViewChild(DxTreeViewComponent, { static: true })
  menu!: DxTreeViewComponent;

  // Manage unsubscrciption
  private ngUnsubscribe = new Subject<void>();

  activeRoute: string | null | undefined;
  category: string | null | undefined;

  roles: string[] = [];

  console = console;

  @Output()
  selectedItemChanged = new EventEmitter<ItemClickEvent>();

  @Output()
  openMenu = new EventEmitter<any>();

  private _selectedItem!: string;
  @Input()
  set selectedItem(value: string) {
    this._selectedItem = value;
    if (!this.menu || !this.menu.instance) {
      return;
    }

    this.menu.instance.selectItem(value);
  }

  private _items!: MainMenuItem[];
  get items() {
    if (!this._items) {
      this._items = [];
      navigation.forEach((item) => {
        if (item.path && !/^\//.test(item.path)) {
          item.path = `/${item.path}`;
        }
        if (this.checkPermission(item.permission)) {
          this._items.push({ ...item, expanded: !this._compactMode });
        }
      });
    }

    return this._items;
  }

  private _compactMode = false;
  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;

    if (!this.menu || !this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }

  constructor(
    private routerUtilSvc: RouterUtilService,
    authSvc: AuthService,
    authState: AuthState
  ) {
    this.routerUtilSvc.location$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.category = null;
        this.activeRoute = value;
        const pathArray = value.split('/');
        if (value.startsWith('/admin/product/detail')) {
          this.category = pathArray[4];
        }
      });
    if (authState.token) {
      this.roles = authSvc.getTokenRoles(authState.token);
    }
  }

  onItemClick(event: ItemClickEvent) {
    this.selectedItemChanged.emit(event);
  }

  /*ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, 'dxclick', (e: Event) => {
      this.openMenu.next(e);
    });
  }*/

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    //events.off(this.elementRef.nativeElement, 'dxclick');
  }

  checkPermission(permissions: string[]) {
    let authorized = false;
    this.roles.forEach((role) => {
      if (permissions?.includes(role)) {
        authorized = true;
      }
    });
    return authorized;
  }
}
