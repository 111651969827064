<footer class="footer-area" [ngClass]="isMobile ? 'mobile' : 'desktop'" *transloco="let t; scope: 'website'">
  <div class="itc_footer_wave" [ngClass]="isMobile ? 'mobile' : ''">
    <div [fxLayout]="isMobile ? 'column' : 'row'" [ngStyle]="{ height: '100%' }" fxLayoutAlign="space-between center"
      fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
      <div [fxFlex]="!isMobile ? 33.3 : 100" fxLayout="column"
        [fxLayoutAlign]="!isMobile ? 'start start' : 'center center'"
        [ngStyle]="{ width: '65%', 'margin-bottom': isMobile ? '0' : '20px' }">
        <div fxLayout="row" fxLayoutAlign="start start">
          <img class="itc_footer_logo" [ngStyle]="isMobile ? { margin: 0, height: '90px' } : {}" [src]="
              isMobile
                ? '/assets/images/logo_full.png'
                : '/assets/images/logo/logo.svg'
            " />
        </div>
        <div [ngStyle]="{ width: '100%' }" fxLayout="row" fxLayoutAlign="end start" *ngIf="!isMobile">
          <div class="itc_uni_column" fxLayout="column" fxLayoutAlign="start start">
            <span class="itc_compliance_span">{{
              "Compliance certificate".toUpperCase()
              }}</span>
            <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="15px">
              <img src="/assets/images/footer/kiwa.png" />
              <img src="/assets/images/footer/eac.png" />
              <span class="itc_compliance_uni_span" [ngStyle]="{ width: '130px' }">UNI EN ISO 9001:2015
                <span>Our operations run according to the UNI EN ISO 9001:2015
                  Quality standards.</span></span>
            </div>
          </div>
        </div>
      </div>
      <div [fxFlex]="!isMobile ? 33.3 : 100" fxLayout="column" [ngClass]="isMobile ? 'mobile' : ''"
        [ngStyle]="{ height: '100%', width: '100%', textAlign: 'center' }" fxLayoutAlign="center center"
        [fxLayoutGap]="isMobile ? '0' : '4px'">
        <span class="itc_middle_footer_content_font_1">{{
          "Vis Hydraulics srl".toUpperCase()
          }}</span>
        <span class="itc_middle_footer_content_font_2">Via Giardini Nord 140, 41026 Pavullo N/F - ITALY</span>
        <span class="itc_middle_footer_content_font_2">ph: +39 0536 20461
          <span *ngIf="isMobile"> | VAT: 03247840360</span></span>
        <span *ngIf="!isMobile" class="itc_middle_footer_content_font_2">VAT: 03247840360</span>
        <span class="itc_middle_footer_content_font_2"><a
            href="mailto:info@vishydraulics.com">info&#64;vishydraulics.com</a></span>
        <div class="itc_middle_footer_content_child_2" fxLayout="row" fxLayoutGap="10px" *ngIf="!isMobile">
          <span><a class="itc_middle_footer_content_link" [routerLink]="['/terms-and-conditions']"
              routerLinkActive="active">{{ t("website.toc") }}</a></span>
          <span><a class="itc_middle_footer_content_link" [routerLink]="['/job-opportunities']"
              routerLinkActive="active">{{ t("website.job_opportunities") }}</a></span>
          <span><a class="itc_middle_footer_content_link" [routerLink]="['/scip-code']" routerLinkActive="active">scip
              code</a></span>
        </div>
        <div class="policy-container">
          <a href="https://www.vishydraulics.com/privacy-policy" class="itc_middle_footer_content_link">Privacy
            Policy</a>
          |&nbsp;<a href="https://vis-website.s3.eu-west-1.amazonaws.com/Codice+etico.pdf"
            class="itc_middle_footer_content_link" target="_blank">MOG 231 –
            Codice Etico</a>
          |&nbsp;<a href="https://vis-website.s3.eu-west-1.amazonaws.com/Parte+Generale+-+versione+internet.pdf"
            class="itc_middle_footer_content_link" target="_blank">MOG 231 –
            Parte Generale</a>
          |&nbsp;<a href="https://vis-website.s3.eu-west-1.amazonaws.com/Procedura+segnalazione+Whistleblowing.pdf"
            class="itc_middle_footer_content_link" target="_blank">Whistleblowing</a>
          |&nbsp;<a
            href="https://vis-website.s3.eu-west-1.amazonaws.com/download-section/Politica+per+la+parita%CC%80+di+genere.pdf"
            class="itc_middle_footer_content_link" target="_blank">POLITICA PER LA PARITÀ DI GENERE</a>
          |&nbsp;<a href="mailto:G_Comitato_Guida_PDR@vishydraulics.com" class="itc_middle_footer_content_link"
            target="_blank">Segnalazioni PDG - abusi e molestie</a>
          |&nbsp;<a href="https://www.vishydraulics.com/cookie-policy" class="itc_middle_footer_content_link">Cookies
            Policy</a>
          |&nbsp;<a href="https://www.vishydraulics.com/privacy-policy-candidates"
            class="itc_middle_footer_content_link">Informativa Candidati</a>
          |&nbsp;<a href="https://www.vishydraulics.com/access-information-policy"
            class="itc_middle_footer_content_link">Informativa Accessi</a>
          |&nbsp;<a href="https://www.vishydraulics.com/policy-fornitori"
            class="itc_middle_footer_content_link">Informativa Fornitori/Clienti</a>
          |&nbsp;<a href="https://www.vishydraulics.com/policy-eventi"
            class="itc_middle_footer_content_link">Informativa Eventi</a>
        </div>
      </div>
      <div [fxFlex]="!isMobile ? 33.3 : 100" fxLayout="column"
        [fxLayoutAlign]="!isMobile ? 'space-evenly end' : 'center end'" [ngStyle]="{
          height: '100%',
          width: isMobile ? '100%' : '30%',
          'margin-right': isMobile ? '0' : '3%'
        }">
        <img *ngIf="!isMobile" class="itc_footer_right_logo" [ngStyle]="isMobile ? { width: '90px' } : {}"
          src="/assets/images/footer/g186.svg" />
        <span class="itc_footer_right_span" [ngStyle]="
            isMobile ? { 'margin-top': '10px', 'font-size': '0.8em' } : {}
          ">Credits by
          <a href="https://www.studioitc.com" terget="_blank">Studio I.T.C</a>
          and Daria Boselli</span>
      </div>
    </div>
  </div>
</footer>