import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MainTypes } from 'src/app/core/models';
import { ProductService } from 'src/app/core/services';

@Component({
  selector: 'app-product-configurator',
  templateUrl: './product-configurator.component.html',
  styleUrls: ['./product-configurator.component.scss'],
})
export class ProductConfiguratorComponent implements OnInit {
  @Input() mainTypes: MainTypes[] = [];
  @Input() isMobile = false;
  @Output() Open = new EventEmitter<void>();

  selectedProdMainType: MainTypes | undefined;

  availablePressures: number[] = [];
  availableFlows: number[] = [];
  availableGroupTypes: any[] = [];

  selectedPressure: number | undefined;
  selectedFlow: number | undefined;
  querySearchSubtitle: string | undefined;
  groupTypeSolenoid: number | undefined;

  constructor(private prdSvc: ProductService, private router: Router) {}

  ngOnInit(): void {
    this.mainTypes = this.mainTypes.filter((type) => type.categ === 'valves');
  }

  prodMainTypesSelected(e: any) {
    if (!this.selectedProdMainType) this.Open.next();
    this.selectedProdMainType = this.mainTypes.find(
      (value) => value.code === e.selectedItem.code
    );
    if (!this.selectedProdMainType) return;

    this.prdSvc
      .getFlowList(this.selectedProdMainType?.code, undefined, undefined)
      .subscribe(({ payload }) => (this.availableFlows = payload));
    this.prdSvc
      .getPressureList(this.selectedProdMainType?.code, undefined, undefined)
      .subscribe(({ payload }) => (this.availablePressures = payload));
    if (this.selectedProdMainType?.code === 'solenoid_valves') {
      this.prdSvc.getSolenoidValvesGroups().subscribe(({ payload }) => {
        this.availableGroupTypes = payload;
      });
    }
  }

  pressureChange(e: any) {
    this.selectedPressure = e.selectedItem;
    if (!this.selectedProdMainType) return;
    if (!this.selectedFlow) {
      this.prdSvc
        .getFlowList(
          this.selectedProdMainType?.code,
          e.selectedItem,
          this.groupTypeSolenoid
        )
        .subscribe((res) => (this.availableFlows = res.payload));
    }
  }
  flowChange(e: any) {
    this.selectedFlow = e.selectedItem;
    if (!this.selectedProdMainType) return;
    if (!this.selectedPressure) {
      this.prdSvc
        .getPressureList(
          this.selectedProdMainType?.code,
          e.selectedItem,
          this.groupTypeSolenoid
        )
        .subscribe((res) => (this.availablePressures = res.payload));
    }
  }

  groupTypeChange(e: any) {
    this.groupTypeSolenoid = e.selectedItem.code;
    if (!this.selectedProdMainType) return;
    this.prdSvc
      .getFlowList(
        this.selectedProdMainType?.code,
        undefined,
        this.groupTypeSolenoid
      )
      .subscribe((res) => (this.availableFlows = res.payload));
    this.prdSvc
      .getPressureList(
        this.selectedProdMainType?.code,
        undefined,
        this.groupTypeSolenoid
      )
      .subscribe((res) => (this.availablePressures = res.payload));
  }

  subtitleFilterChange(e: any) {
    this.querySearchSubtitle = e.value;
  }

  gotoSelectedType() {
    this.router.navigate(['/product/list', this.selectedProdMainType?.code], {
      queryParams: {
        flow: this.selectedFlow,
        pressure: this.selectedPressure,
        subtitle: this.querySearchSubtitle,
        groupType: this.groupTypeSolenoid,
      },
    });
  }
}
