import {
  Computed,
  DataAction,
  Persistence,
  StateRepository,
} from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';
import { Lang, LangStateModel } from '../models';
@Persistence()
@StateRepository()
@State<LangStateModel>({
  name: 'languages',
  defaults: {
    selectedLang: 1,
    langList: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class LangState extends NgxsDataRepository<LangStateModel> {
  constructor() {
    super();
  }

  @Selector()
  public static selectedLang(state: LangStateModel): number {
    return state.selectedLang;
  }
  @Computed()
  public get availableLanguages(): Lang[] {
    return this.snapshot.langList;
  }

  @Computed()
  public get defaultLanguage(): number {
    const defaultNotFound = 1; // english
    const defaultLang = this.snapshot.langList?.find((l) => l.isDefault);
    return defaultLang ? defaultLang.id : defaultNotFound;
  }

  @DataAction()
  public selectLang(langId: number): void {
    this.patchState({
      selectedLang: langId,
    });
  }
}
