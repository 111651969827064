import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-website-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class WebsiteMainMenuComponent {
  @Input() secondary = false;
  @Input() mobile = false;
  @Output() toggleMenu = new EventEmitter<boolean>();
}
