import { Component, OnInit } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { take } from 'rxjs/operators';
import { Lang } from 'src/app/core/models';
import { LangService } from 'src/app/core/services';
import { LangState } from 'src/app/core/state';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @SelectSnapshot(LangState.selectedLang) currentLang!: number;
  languages!: Lang[];
  languageId = this.currentLang;

  constructor(private langState: LangState, private langSvc: LangService) {
    /* this.languages.forEach((l) => {
      if (l.isDefault) {
        this.languageId = l.id;
        this.langState.selectLang(l.id);
      }
    }); */
  }

  ngOnInit(): void {
    this.langSvc
      .loadLanguages()
      .pipe(take(1))
      .subscribe((availableLang) => {
        /* const defaultNotFound = 1; // english
        const defaultLang = availableLang.find((l) => l.isDefault); */
        this.languages = availableLang;

        this.langState.patchState({
          langList: availableLang,
        });
      });
  }

  onLanguageChanged(e: any) {
    /* switch (e.value) {
      case 1:
        this.transloco.setActiveLang('en');
        break;
      case 3:
        this.transloco.setActiveLang('cn');
        break;
      case 4:
        this.transloco.setActiveLang('ru');
        break;
    } */
    this.langState.selectLang(e.value);
  }
}
