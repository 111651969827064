import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-home-video',
  templateUrl: './home-video.component.html',
  styleUrls: ['./home-video.component.scss'],
})
export class HomeVideoComponent implements OnInit {
  @ViewChild('itc_video') video!: ElementRef;
  @Input() videoUrl!: string;
  @Input() coverImage!: string;

  eventSub!: Subscription;

  ngOnInit(): void {
    if (this.videoUrl != null) {
      this.videoUrl = this.videoUrl.trim();
    }

    this.eventSub = fromEvent(window, 'scroll')
      .pipe(
        throttleTime(300), // emits once, then ignores subsequent emissions for 300ms, repeat...
        tap((event) => {
          if (this.video) {
            this.video.nativeElement.muted = true;
            const rect = this.video.nativeElement.getBoundingClientRect();
            const topShown = rect.top >= 0;
            const bottomShown = rect.bottom <= window.innerHeight;
            if (topShown && bottomShown) {
              this.video.nativeElement.play();
            } else {
              this.video.nativeElement.pause();
            }
          }
        })
      )
      .subscribe();
  }
}
