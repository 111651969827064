import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import CustomStore from 'devextreme/data/custom_store';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, UpdateRequest } from '../models';
import { News, NewsPicture } from '../models/cms/news.model';
import { EndpointService } from './endpoint.service';
import { GridCustomStoreService } from './grid-custom-store.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { LangState } from '../state';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  @SelectSnapshot(LangState.selectedLang) currentLang!: number;

  constructor(
    private http: HttpClient,
    private epSvc: EndpointService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private transloco: TranslocoService,
    private gridCsSvc: GridCustomStoreService
  ) {}

  getNewsCustomStore(): CustomStore {
    return new CustomStore({
      key: 'id', // revision
      load: (loadOptions: any) => {
        const langId = this.currentLang;
        if (langId > 0) {
          loadOptions.userData['id_lang'] = langId;
        }

        return this.gridCsSvc.loadStoreData<News>(
          this.epSvc.getApiEndpoint('news/list'),
          loadOptions
        );
      },
    });
  }

  loadNewsById(id: number): Observable<News> {
    return this.http
      .get<ApiResponse>(
        this.epSvc.getApiEndpoint(
          `news/front/${id}?id_lang=${this.currentLang}`
        )
      )
      .pipe(
        map((response) => {
          if (response.status === 'SUCCESS') {
            return response.payload;
          } else {
            this.logger.error(response.payload);
            this.toastr.error(
              this.transloco.translate('errors.cannot_load_news')
            );
          }
        })
      );
  }

  getNewsList(): Observable<News[]> {
    return this.http
      .get<ApiResponse>(this.epSvc.getApiEndpoint('news'))
      .pipe(map((response) => response.payload));
  }

  getNewsImages(newsId: number): Observable<NewsPicture[]> {
    return this.http
      .get<ApiResponse>(this.epSvc.getApiEndpoint(`news/${newsId}/image`))
      .pipe(map((response) => response.payload));
  }

  deleteNewsImage(newsId: number, main: boolean): Observable<boolean> {
    return this.http.delete<boolean>(
      this.epSvc.getApiEndpoint(
        'news/image?news_id=' + newsId + '&main=' + main
      )
    );
  }

  updateNewsField(id: number, req: UpdateRequest): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(
      this.epSvc.getApiEndpoint(`news/${id}?id_lang=${this.currentLang}`),
      req
    );
  }

  createNews(news: News): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.epSvc.getApiEndpoint('news'), {
      title: news.title,
      description: news.description,
      source: news.source,
      status: news.status,
      idLang: this.currentLang,
    });
  }

  deleteNews(id: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.epSvc.getApiEndpoint('news/' + id)
    );
  }

  getLinkedinAuthUrl(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.epSvc.getApiEndpoint('linkedin/request-auth')
    );
  }
}
