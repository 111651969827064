import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../models';
import { HomeBoxes } from '../models/home/boxes/home_boxes';
import { News } from '../models/cms/news.model';
import { EndpointService } from './endpoint.service';
import { EventsModel } from '../models/cms/events.model';
import { Csr } from '../models/cms/csr.model';
import { Welfare } from '../models/cms/welfare.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { LangState } from '../state';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private epSvc: EndpointService, private http: HttpClient) {}

  @SelectSnapshot(LangState.selectedLang) currentLang!: number;

  getNewsBoxes(limit: number | null): Observable<News[]> {
    return this.http
      .get<ApiResponse>(
        this.epSvc.getApiEndpoint('news/front'),
        limit
          ? {
              params: { limit, id_lang: this.currentLang },
            }
          : { params: { id_lang: this.currentLang } }
      )
      .pipe(map((res) => res.payload));
  }

  getEventsBoxes(limit: number | null): Observable<EventsModel[]> {
    return this.http
      .get<ApiResponse>(
        this.epSvc.getApiEndpoint('events/front'),
        limit
          ? {
              params: { limit },
            }
          : {}
      )
      .pipe(map((res) => res.payload));
  }

  getWelfareBoxes(limit: number | null): Observable<Welfare[]> {
    return this.http
      .get<ApiResponse>(
        this.epSvc.getApiEndpoint('welfare/front'),
        limit
          ? {
              params: { limit },
            }
          : {}
      )
      .pipe(map((res) => res.payload));
  }

  getCsrBoxes(limit: number | null): Observable<Csr[]> {
    return this.http
      .get<ApiResponse>(
        this.epSvc.getApiEndpoint('csr/front'),
        limit
          ? {
              params: { limit },
            }
          : {}
      )
      .pipe(map((res) => res.payload));
  }

  getHomeBoxes(section: string): HomeBoxes[] {
    switch (section) {
      case 'aboutUs':
        return this.getHomeAboutUsBoxes();
      case 'supports':
        return this.getHomeSupportsBoxes();
      case 'pastEvents':
        return this.getPastEventsBoxes();
    }
    return [];
  }

  getHomeAboutUsBoxes(): HomeBoxes[] {
    const boxes: HomeBoxes[] = [];
    boxes.push({
      id: 1,
      image: 'assets/images/tempImages/weber_hydraulik.png',
      description:
        'The exchange with the various departments is always very stimulating, efficient, and problem-solving oriented. The contact persons are all experts in their field. They are polite, reliable, and dependable across the board.',
    });
    boxes.push({
      id: 1,
      image: 'assets/images/tempImages/hidros-logo-.png',
      description:
        'We’re happy to work closely with VIS as a long-term partner. All colleagues understand and care for our requests with a professional approach. It’s an honor for us to carry their brand together  with quality products in hydraulic systems that we manufacture.',
    });
    boxes.push({
      id: 2,
      image: 'assets/images/tempImages/related.png',
      description:
        'A very professional and friendly company. They always try their best to accommodate any urgent demand and at disposal for any kind of advice, to reach a positive outcome. Glad to work with truly passionate suppliers that care about their products and look after their customers.',
    });
    boxes.push({
      id: 3,
      image: 'assets/images/tempImages/wtb.jpg',
      description:
        'Very pleased with the collaboration with VIS; they’re solution oriented and quick to respond, always willing to help. They provide good informations and everything is made in the correct way; order confirmations, invoices, delivery and packaging.',
    });
    boxes.push({
      id: 4,
      image: 'assets/images/tempImages/bucher.jpg',
      description:
        'VIS is a long-term partner for Bucher worldwide. Quality, service and collaboration are common values founding a trusty relationship.',
    });
    boxes.push({
      id: 5,
      image: 'assets/images/tempImages/DMT.png',
      description:
        'The essence of Italian technology transformed into competitive and innovative products. Passion, competence, sharp vision future on hydraulics industry, combined with great attention to sustainability and human relationship, make VIS an outstanding reality in our field.',
    });

    return boxes;
  }

  getHomeSupportsBoxes(): HomeBoxes[] {
    const boxes: HomeBoxes[] = [];
    boxes.push({
      id: 1,
      image: '/assets/images/tempImages/cimonesci.png',
      link: 'https://www.cimonesci.it/',
    });
    boxes.push({
      id: 2,
      image: '/assets/images/tempImages/MV_main-logo_OFFICIAL_PARTNER-01.png',
      link: 'https://www.modenavolley.it/',
    });
    boxes.push({
      id: 3,
      image: '/assets/images/tempImages/barbieri.png',
      link: 'https://www.instagram.com/rachelebarbieri/',
    });
    return boxes;
  }

  getPastEventsBoxes(): HomeBoxes[] {
    const boxes: HomeBoxes[] = [];
    boxes.push({
      id: 1,
      image: '/assets/images/tempImages/pastEvents/1.png',
      title: 'Conexpo, Las Vegas - usa',
      description: 'LAS VEGAS - USA 10-14 March 2020 Booth S80657 - Hall 43',
    });
    boxes.push({
      id: 2,
      image: '/assets/images/tempImages/pastEvents/2.png',
      title: 'BAuma russia 2020',

      description: 'MOSCA - RUSSIA 10-14 March 2020 Booth S80657 - Hall 43',
    });
    boxes.push({
      id: 3,
      image: '/assets/images/tempImages/pastEvents/3.png',
      title: 'Eima Bologna 2020',

      description: 'BOLOGNA - ITALY 10-14 March 2020 Booth S80657 - Hall 43',
    });
    boxes.push({
      id: 4,
      image: '/assets/images/tempImages/pastEvents/4.png',
      title: 'Bruma conexpo india',

      description: 'LAS VEGAS - USA 10-14 March 2020 Booth S80657 - Hall 43',
    });
    boxes.push({
      id: 5,
      image: '/assets/images/tempImages/pastEvents/5.png',
      title: 'Win eurasia 2018',

      description: 'LAS VEGAS - USA 10-14 March 2020 Booth S80657 - Hall 43',
    });
    boxes.push({
      id: 6,
      image: '/assets/images/tempImages/pastEvents/6.png',
      title: 'Eima 2018',

      description: 'LAS VEGAS - USA 10-14 March 2020 Booth S80657 - Hall 43',
    });
    boxes.push({
      id: 7,
      image: '/assets/images/tempImages/pastEvents/7.png',
      title: 'Excon 2017',

      description: 'LAS VEGAS - USA 10-14 March 2020 Booth S80657 - Hall 43',
    });
    boxes.push({
      id: 8,
      image: '/assets/images/tempImages/pastEvents/8.png',
      title: 'AGRITECHNICA 2017',

      description: 'LAS VEGAS - USA 10-14 March 2020 Booth S80657 - Hall 43',
    });
    boxes.push({
      id: 10,
      image: '/assets/images/tempImages/pastEvents/10.png',
      title: 'Icuee - Kentucky trade show',

      description: 'LAS VEGAS - USA 10-14 March 2020 Booth S80657 - Hall 43',
    });
    boxes.push({
      id: 11,
      image: '/assets/images/tempImages/pastEvents/11.png',
      title: 'Ptc asia',
      description: 'LAS VEGAS - USA 10-14 March 2020 Booth S80657 - Hall 43',
    });
    return boxes;
  }

  videoUrl(): string {
    return 'https://vis-website.s3.eu-west-1.amazonaws.com/vis_video.mp4';
  }
}
