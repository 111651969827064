import { Component, Input, OnDestroy } from '@angular/core';
import { OpenedStateMode, RevealMode } from 'devextreme/ui/drawer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScreenService } from 'src/app/core/services';

@Component({
  selector: 'app-website-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class WebsiteHeaderComponent implements OnDestroy {
  @Input() secondary = false;
  @Input() location = '/';

  isMobile: boolean = this.scrnSvc.isMobile;
  isTablet: boolean = this.scrnSvc.sizes['screen-medium'];
  mobileDrawerOpened = false;
  destroyed = new Subject<void>();

  menuMode: OpenedStateMode = 'overlap';
  menuRevealMode: RevealMode = 'slide';
  minMenuSize = 0;
  shaderEnabled = false;

  drawerWidth: number = window.innerWidth * 0.8;

  showLangList = false;

  constructor(private scrnSvc: ScreenService) {
    this.toggleMenu = this.toggleMenu.bind(this);
    scrnSvc.changed.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.isMobile = scrnSvc.isMobile;
      this.isTablet = scrnSvc.sizes['screen-medium'];
    });
  }

  toggleMenu() {
    this.mobileDrawerOpened = !this.mobileDrawerOpened;
  }

  toggleLang(e: MouseEvent) {
    this.showLangList = !this.showLangList;
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
