export interface ValveTechDataLang {
  id: number | null,
  idRevision: number | null,
  idLang: number | null,
  auditUser: string | null,
  fluids: string,
  orientation: string,
  wireSealsTamperProof: string,
  maximumInternalLeakageNote: string,
  maximumOperationPressureNote: string,
  maximumFlowNote: string,
  maximumInternalLeakageNote2: string,
  variousVoltageOptionAvailable: string,
  powerNote: string,
  pressureSettingEstablishedNote: string
}

export function emptyValveTechDataLang(idLang: number): ValveTechDataLang {
  return {
    id: null,
    idRevision: null,
    idLang: idLang,
    auditUser: null,
    fluids: 'Mineral - based or synthetic with lubricating properties',
    orientation: 'No restrictions',
    wireSealsTamperProof: '',
    maximumInternalLeakageNote: '',
    maximumOperationPressureNote: '',
    maximumFlowNote: '',
    maximumInternalLeakageNote2: '',
    variousVoltageOptionAvailable: '',
    powerNote: '',
    pressureSettingEstablishedNote: ''
  };
}
