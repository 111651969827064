import { gql } from 'apollo-angular';

export class ProductQueries {
  static COMPLTETE_VALVE(valveRevisionId: number, idLang: number) {
    return gql`
    {
      valveRevision(revisionId: ${valveRevisionId}) {
            id
            idValve
            auditUser
            rev
            year
            revisionDate
            status
            valve {
                id
                idType
                auditUser
                basicCode
                cavityCode
                markingCode
                configCode
                configCode2
                configCode2Allow
                status
                idCategory
                groupType
                valveType(idLang: ${idLang}) {
                  id
                  name
                  options {
                    fld_min_pul_vlt
                    fld_set_press
                    fld_press_set_rng
                    fld_ac_crack_pres
                    fld_press_set_est
                    fld_res_press
                    fld_tigh_torq_nut
                    fld_pl_tamp_proof_cap
                    fld_wire_seal_tamp_proof
                    fld_swi_prs
                    fld_max_xomp_byp
                    fld_max_comp_pri
                    fld_comp_spring_acc
                    fld_pilot_ratio
                    fld_coin_kit
                    fld_coin_kit2
                    fld_coil2
                    fld_inst_tool
                    fld_seal_kit
                    fld_inst_torque
                    fld_oring_temp_rng
                    fld_ext_comp_treat
                    fld_max_int_leak
                    fld_coil
                    fld_switch_on
                    fld_switch_off
                    fld_tigh_torq_blind_nut
                    tbl_bias_spring
                    tbl_cracking_pressure
                    tbl_switching_pressure
                    tbl_pressure_setting_range
                    tbl_maximum_internal_leakage
                    tbl_frc
                    tbl_frs
                    tbl_frd
                    tbl_basic_options
                    tbl_spring_options
                    tbl_fixed_flow_setting
                    tbl_steel_wire_options
                    tbl_flow_setting_range
                    tbl_filtration
                    tbl_flow_path
                    tbl_flow_adjustment
                    tbl_manual_override
                    tbl_config_code_option
                  },
                  groupOptions {
                    code,
                    value
                  }
                }
            }
            valvePictures {
                id
                idRevision
                auditUser
                symbolFile
                tredModelFile
                designFile
                crossSectionFile
                crossSectionDetailA
                crossSectionDetailB
                crossSectionDetailC
                crossSectionDetailD
                cavityDetailsFile
                cavityDetailsFile2
                externalDimensionsFile
                circuitsFile
                cavityDetailX
                cavityDetailY
                cavityDetailZ
                cavityDetailW
                cavityName
                cavityName2,
                stepFile
            }
            valveTechData {
                id
                idRevision
                auditUser
                maximumOperatingPressure
                maximumFlow
                minimumPullInVoltage
                maximumInternalLeakage1 {
                    val1
                    val2
                    type
                }
                maximumInternalLeakage2 {
                    val1
                    val2
                    type
                }
                externalComponentTreatment {
                    val1
                    val2
                }
                oringTemperatureRange1 {
                    val1
                    val2
                }
                oringTemperatureRange2 {
                    val1
                    val2
                }
                oringTemperatureRange2Allow
                oringTemperatureRange3 {
                    val1
                    val2
                }
                oringTemperatureRange3Allow
                oilTemperatureRange {
                    val1
                    val2
                }
                viscosities {
                    val1
                    val2
                }
                filtration {
                    val1
                    val2
                    val3
                }
                installationTorque {
                    val1
                    val2
                    val3
                }
                pilotRatio {
                    val1
                    val2
                }
                compensatorSpringAccuracy1 {
                    val1
                    val2
                }
                compensatorSpringAccuracy2 {
                    val1
                    val2
                }
                maximumCompensatedPriorityFlow1 {
                    val1
                    val2
                }
                maximumCompensatedPriorityFlow2 {
                    val1
                    val2
                }
                maximumCompensatedBypassFlow1 {
                    val1
                    val2
                }
                maximumCompensatedBypassFlow2 {
                    val1
                    val2
                }
                antiCavCrackingPressure
                pressureSettingEstablished
                reseatPressure
                tighteningTorqueNut {
                    val1
                    val2
                    val3
                }
                tighteningTorqueBlindNut {
                    val1
                    val2
                    val3
                }
                pressureSettingRange {
                    val1
                    val2
                }
                plasticTamperProofCap
                sealKit
                idCoiningKit
                techCoiningKit {
                  code
                }
                idCoiningKit2
                techCoiningKit2 {
                  code
                }
                installationTool
                coil
                coil2
                weight
                oilTestingCondition
                switchOnTime
                switchOffTime
                maximumFlowThermalReliefValve
                thermalReliefCrackPressure2To1 {
                  val1
                  val2
                  val3
                  val4
                }
                externalComponentTreatmentVal2Allow
                coil2Allow
                voltage {
                    val1
                    val2
                }
                input {
                    val1
                    val2
                }
                pwmFrequency {
                    val1
                    val2
                }
                operatingRange {
                    val1
                    val2
                }
                connector
                protectionClass
                maxCurrent
                numElectricalContacts
                power
                power2
                coilInsulationClass {
                  val1
                  val2
                }
                wireInsulationClass {
                  val1
                  val2
                }
                ed
                supplyPowerTolerance {
                  val1
                  val2
                  val3
                }
                ambientTemperatureRange1 {
                  val1
                  val2
                }
                ambientTemperatureRange2 {
                  val1
                  val2
                }
                ipCoilOringNut
            }
        }
    }`;
  }

  static COMPLETE_VALVE_LANG(valveRevisionId: number, langId: number) {
    return gql`
      {
        valveRevision(revisionId: ${valveRevisionId}) {
              id
              valveLang (idLang: ${langId}) {
                  id
                  idRevision
                  idLang
                  auditUser
                  basicCodeDescription
                  cavityCodeDescription
                  markingCodeDescription
                  configCodeDescription
                  subtitle1
                  subtitle2
                  subtitle3
                  note
                  description
                  tecnicalFeatures
                  chartNoteFP
                  chartNotePF
                  chartNoteCF
                  chartNoteOL
                  orderingCodeGlobalNote
                  biasOptionNote
                  designNote
                  fixedFlowSettingNote,
                  filtrationNote
                  flowPathNote
                  flowAdjustmentNote
                  manualOverrideNote
                  externalDimensionNote1
                  externalDimensionNote2
                  externalDimensionNote3
                  availableNut1
                  availableNut2
                  availableNut3
              }
              valveTechDataLang(idLang: ${langId}) {
                  id
                  idRevision
                  idLang
                  auditUser
                  fluids
                  orientation
                  wireSealsTamperProof
                  maximumInternalLeakageNote
                  maximumOperationPressureNote
                  maximumFlowNote
                  maximumInternalLeakageNote2
                  variousVoltageOptionAvailable
                  powerNote
                  pressureSettingEstablishedNote
              }
          }
      }
    `;
  }

  static ORDERING_CODE(valveRevisionId: number) {
    return gql`
      {
          valveRevision(revisionId: ${valveRevisionId}){
            id
            idValve

            valve{
              basicCode
              cavityCode
              markingCode
              configCode
            }

            valveOrderingCodeOptions(idLang: 1) {
              biasSpringOptions {
                id
                springModelCode
              }
              filtrationOptions {
                id
              }
            }
          }
        }
    `;
  }
}
