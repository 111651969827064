<ng-container *transloco="let t; scope: 'website'">
  <div class="itc_box" fxLayout="column" fxLayoutAlign="start center" [ngStyle]="{
      height: type === 1 ? '450px' : '500px',
      'padding-bottom': type === 1 ? '20px' : 0
    }">
    <ng-container *ngIf="type === 0">
      <div class="itc_box_header" fxFlex="15" fxLayout="row" fxLayoutAlign="start center">
        <img class="itc_social_img" *ngIf="box.source === 'facebook'" src="/assets/images/social/facebook.png"
          alt="facebook logo" />
        <img class="itc_social_img" *ngIf="box.source === 'linkedin'" src="/assets/images/social/linkedin_blu.png"
          alt="linkedin logo" />
        <img class="itc_social_img" *ngIf="box.source === 'admin' || box.source === null"
          src="/assets/images/logo_full_white.png" alt="vis hydraulics logo" />
        <div *ngIf="box.likes" class="itc_social_score" fxLayout="row">
          <img src="/assets/images/icons/like_fb.png" alt="Facebook like logo" />{{ box.likes }}
        </div>
        <div *ngIf="box.comments" class="itc_social_score" fxLayout="row">
          <img src="/assets/images/icons/commenti_fb.png" alt="Facebook comments logo" />{{ box.comments }}
        </div>
        <div *ngIf="box.shares" class="itc_social_score" fxLayout="row">
          <img src="/assets/images/icons/share_fb.png" alt="Facebook shares logo" />{{ box.shares }}
        </div>
        <div *ngIf="box.date" class="itc_data" fxFlex [ngStyle]="{
            color:
              box.source === 'facebook'
                ? '#3b5998'
                : box.source === 'linkedin'
                ? '#0F7AB9'
                : '#303A4C'
          }">
          {{ box.date }}
        </div>
      </div>
      <span fxFlex="10" class="itc_margin itc_title_font" [ngStyle]="{
          color:
            box.source === 'facebook'
              ? '#3b5998'
              : box.source === 'in'
              ? '#0F7AB9'
              : '#303A4C'
        }">{{ box.title }}</span>
      <span fxFlex="25" class="itc_margin itc_description_font"
        [innerHtml]="handleLongDescription(box.description, 100)"></span>
      <div *ngIf="box.pictures" fxFlex="50" class="itc_box_image" [ngStyle]="{
          'background-image': 'url(' + getImageUrl(box) + ')'
        }">
        <a fxLayout="row" fxLayoutAlign="end center" class="itc_box_image_overlap"
          [href]="'/' + this.boxType + '/' + box.id" [ngStyle]="{
            'background-color':
              box.source === 'facebook'
                ? '#3b5998'
                : box.source === 'in'
                ? '#0F7AB9'
                : '#303A4C'
          }">{{ t("read_article") }}</a>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 1">
      <div class="itc_box_image_1">
        <img style="width: 100%; height: 100%; object-fit: contain"
          [src]="imageIsEndpointed ? imgBaseUrl + '/' + box.id : box.image" alt="Post image" />
      </div>
      <div class="itc_box_title_1">
        <span>{{ box.title }}</span>
      </div>
      <div class="itc_margin itc_divisor"></div>
      <div class="itc_padding itc_description_font_type_1" [innerHtml]="box.description"></div>
    </ng-container>
  </div>
</ng-container>