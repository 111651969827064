import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services';
import { AuthState } from 'src/app/core/state';

@Component({
  selector: 'app-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AdminHeaderComponent implements OnInit, OnDestroy {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  user: string | undefined;

  @Select(AuthState.token) token$: Observable<string> | undefined;

  // Manage unsubscrciption
  private ngUnsubscribe = new Subject<void>();

  dropdownItems = [
    {
      name: 'Go to website',
      icon: 'globe',
      onClick: () => {
        this.router.navigate(['/']);
      },
    },
    {
      name: 'Profile',
      icon: 'user',
      onClick: () => {
        this.router.navigate(['/admin/profile']);
      },
    },
    {
      name: 'Logout',
      icon: 'runner',
      onClick: () => {
        this.authState.signOut();
      },
    },
  ];

  constructor(
    private authState: AuthState,
    private authSvc: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.token$?.pipe(takeUntil(this.ngUnsubscribe)).subscribe((t) => {
      if (t) {
        this.user = this.authSvc.getTokenUserFullName(t);
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  };
}
