<app-page-loader></app-page-loader>

<app-website-header
  *ngIf="!isMobile"
  [location]="location"
  [secondary]="pathMatch()"
></app-website-header>
<app-website-right-toolbar
  fxHide.xs
  fxHide.sm
  *ngIf="!isMobile"
></app-website-right-toolbar>
<ng-container *ngIf="!isMobile">
  <ng-container *ngTemplateOutlet="template"></ng-container>
</ng-container>
<app-website-footer *ngIf="!isMobile"></app-website-footer>

<app-website-header
  [location]="location"
  [secondary]="pathMatch()"
  *ngIf="isMobile"
>
  <ng-container *ngTemplateOutlet="template"></ng-container>
  <app-website-footer [isMobile]="isMobile"></app-website-footer>
</app-website-header>

<ng-template #template>
  <router-outlet> </router-outlet>
</ng-template>
