export const navigation = [
  {
    text: 'menu.dashboard',
    icon: 'mediumiconslayout',
    permission: ['ADMIN'],
    path: '/admin',
  },
  {
    text: 'menu.cms',
    icon: 'textdocument',
    permission: ['ADMIN'],
    items: [
      {
        text: 'menu.news',
        permission: ['ADMIN'],
        path: '/admin/news',
      },
      {
        text: 'menu.events',
        permission: ['ADMIN'],
        path: '/admin/events',
      },
      {
        text: 'menu.csr',
        permission: ['ADMIN'],
        path: '/admin/csr',
      },
      {
        text: 'menu.welfare',
        permission: ['ADMIN'],
        path: '/admin/welfare',
      },
      {
        text: 'menu.sponsorship',
        permission: ['ADMIN'],
        path: '/admin/sponsorship',
      },
      {
        text: 'menu.about-us',
        permission: ['ADMIN'],
        path: '/admin/about-us',
      },
      {
        text: 'menu.prod-tech',
        permission: ['ADMIN'],
        path: '/admin/prod-tech',
      },
      {
        text: 'menu.job-opportunities',
        permission: ['ADMIN'],
        path: '/admin/job-opportunities',
      },
    ],
  },
  {
    text: 'menu.product',
    icon: 'vis icon-package',
    permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
    items: [
      {
        text: 'menu.valves',
        path: '/admin/product/list/valves',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'valves',
      },
      {
        text: 'menu.proportional_regulator',
        path: '/admin/product/list/propreg',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'propreg',
      },
      {
        text: 'menu.solenoid_connector',
        path: '/admin/product/list/solconn',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'solconn',
      },
      {
        text: 'menu.coils',
        path: '/admin/product/list/coils',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'coils',
      },
      {
        text: 'menu.coil_connector',
        path: '/admin/product/list/coilconn',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'coilconn',
      },
      {
        text: 'menu.solenoid_valve_manual_override',
        path: '/admin/product/list/solvlvmov',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'solvlvmov',
      },
      {
        text: 'menu.coining_kit',
        path: '/admin/product/list/coinkit',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'coinkit',
      },
      {
        text: 'menu.plastic_tamper_proof_cap',
        path: '/admin/product/list/plstmpprfc',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'plstmpprfc',
      },
      {
        text: 'menu.flow_adjustment',
        path: '/admin/product/list/flowadj',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'flowadj',
      },
      {
        text: 'menu.adj_knob',
        path: '/admin/product/list/adjknob',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'adjknob',
      },
      {
        text: 'menu.cavity_plug',
        path: '/admin/product/list/cavplugs',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'cavplugs',
      },
      {
        text: 'menu.cavity',
        path: '/admin/product/list/cavities',
        permission: ['ADMIN', 'TRANSLATOR', 'DESIGNER'],
        category: 'cavities',
      },
    ],
  },
  {
    text: 'menu.configuration',
    icon: 'preferences',
    permission: ['ADMIN'],
    items: [
      {
        text: 'menu.catalog',
        permission: ['ADMIN'],
        path: '/admin/config/catalog',
      },
      {
        text: 'menu.valve-types',
        permission: ['ADMIN'],
        path: '/admin/config/valve-types',
      },
    ],
  },
];
