<ng-container *transloco="let t">
  <div class="home-back" [ngClass]="isMobile ? 'mobile' : 'desktop'">
    <app-product-configurator [mainTypes]="prodMainTypes" [isMobile]="isMobile"
      (Open)="onOpenConfigurator()"></app-product-configurator>
  </div>
  <div id="home-tabs" class="home-news-tabs" [ngClass]="isMobile ? 'mobile' : ''">
    <div class="itc-mouse" [title]="t('home.SCROLL_TIP')" (click)="scrollToNextSection()" *ngIf="!isMobile">
      <div class="scroll-downs">
        <div class="mouse">
          <div class="scroller"></div>
        </div>
      </div>
    </div>
    <ul fxLayout="row" fxLayoutAlign="center center">
      <li *ngFor="let tab of tabs" [class.active]="selectedTabIndex === tabs.indexOf(tab)"
        (click)="changeTab(tabs.indexOf(tab))">
        {{ tab }}
      </li>
    </ul>
    <div>
      <div class="tab-content">
        <div class="itc_middle_column" [ngClass]="isMobile ? 'mobile' : ''">
          <dx-multi-view [dataSource]="tabs" [(selectedIndex)]="selectedTabIndex" [loop]="false"
            [animationEnabled]="true" [swipeEnabled]="false" style="text-align: center" *ngIf="!loading">
            <div *dxTemplate="let tab of 'item'">
              <app-boxes [type]="tab !== 'Events' ? 0 : 1" [tab]="boxes" [viewAllLink]="viewAllLink"></app-boxes>

              <div class="itc_view_all brandon-font">
                <a [routerLink]="viewAllLink">View all {{ tab }}</a>
              </div>
            </div>
          </dx-multi-view>
          <div *ngIf="loading" style="height: 500px" fxLayout="row" fxLayoutAlign="center center">
            <dx-load-indicator id="medium-indicator" height="30" width="30"></dx-load-indicator>
          </div>
          <app-title [type]="0" [boxTitle]="t('company_presentation') | uppercase"></app-title>
          <app-home-video [videoUrl]="videoUrl"></app-home-video>
          <app-title [type]="1" [centered]="true" [boxTitle]="t('flessibilita_servizio_e_compettivita')"></app-title>
          <app-title [type]="0" [boxTitle]="t('dicono_di_noi') | uppercase"></app-title>
          <app-boxes [type]="1" [tab]="aboutUsBoxes" viewAllLink="about-us"></app-boxes>
          <app-title [type]="0" [boxTitle]="t('we_proudly_support') | uppercase"></app-title>
          <!-- <app-boxes [type]="2" [tab]="supportsBoxes"></app-boxes> -->
          <div class="itc_support_div" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxFlex class="itc_support_item" *ngFor="let box of supportsBoxes">
              <a *ngIf="box.link" class="itc_support_image" [ngStyle]="{ 'background-image': 'url(' + box.image + ')' }"
                [href]="box.link" target="_blank">
              </a>

              <div *ngIf="!box.link" class="itc_support_image"
                [ngStyle]="{ 'background-image': 'url(' + box.image + ')' }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>