import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { AuthState } from '../state';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  @SelectSnapshot(AuthState.token) token: string | undefined;

  constructor(private authSvc: AuthService, private router: Router) {}

  checkPermission(userPermissions: string[], permissions: string[]) {
    return userPermissions.some((p) => permissions?.includes(p));
  }

  hasPermission(component: any): boolean {
    let userPermissions: string[] = [];
    if (component.data.permission.includes('*')) return true;
    if (this.token) {
      userPermissions = this.authSvc.getTokenRoles(this.token);
    }
    if (this.checkPermission(userPermissions, component.data.permission)) {
      return true;
    }

    this.router.navigate(['/admin']);

    return false;
  }

  getUserRoles(): string[] {
    if (this.token) return this.authSvc.getTokenRoles(this.token);
    else return [];
  }
}
