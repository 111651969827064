import { Component, Input, OnInit } from '@angular/core';
import { EndpointService } from 'src/app/core/services';

@Component({
  selector: 'app-single-box',
  templateUrl: './single-box.component.html',
  styleUrls: ['./single-box.component.scss'],
})
export class SingleBoxComponent implements OnInit {
  @Input() type: number | undefined;
  @Input() box!: any;
  @Input() boxType: string | undefined;
  imgBaseUrl: string | undefined;
  imageIsEndpointed = true;

  constructor(private epSvc: EndpointService) {}

  ngOnInit(): void {
    if (this.boxType?.includes('past-events')) {
      this.boxType = 'events';
    } else if (this.boxType?.includes('csr')) {
      this.boxType = 'csr';
    } else if (this.boxType?.includes('welfare')) {
      this.boxType = 'welfare';
    } else if (this.boxType?.includes('sponsorships')) {
      this.boxType = 'news';
    } else if (this.boxType?.charAt(0) === '/') {
      this.boxType = this.boxType!.slice(1);
    } else {
      this.imageIsEndpointed = false;
    }
    this.imgBaseUrl = this.epSvc.getApiEndpoint(this.boxType + '/image');
  }

  handleLongDescription(string: string | undefined, cap: number): string {
    if (string != null) {
      string = string.trim();
      if (string.length >= cap) {
        string = string.slice(0, cap + 10) + '...';
      }
      return string;
    }
    return '';
  }

  getImageUrl(box: any): string {
    if (box.pictures.length) {
      if (box.source === 'facebook') {
        return box.pictures[0].path;
      }
      return this.imgBaseUrl + '/' + box.pictures[0].id;
    }
    return 'assets/images/logo/VIS_LOGO1024_1.jpg';
  }
}
