import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from 'src/app/core/models';
import { EndpointService } from 'src/app/core/services/endpoint.service';
import { ResetPwdModel } from '../models/auth/reset-pwd.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private epSvc: EndpointService) {}

  signIn(username: string, password: string): Observable<ApiResponse> {
    const endPoint = this.epSvc.getApiEndpoint('auth/sign-in');

    return this.http.post<ApiResponse>(endPoint, {
      username: username,
      password: password,
    });
  }

  signOut(userId: number): Observable<void> {
    const endPoint = this.epSvc.getApiEndpoint('auth/sign-out');

    return this.http.get<void>(endPoint, {
      params: { user_id: userId },
    });
  }

  resetPwd(rstPwd: ResetPwdModel): Observable<void> {
    return this.http.post<void>(
      this.epSvc.getApiEndpoint('auth/password-reset'),
      rstPwd
    );
  }

  public getTokenUserFullName(token: string): string {
    const decodedToken = this.decodeToken(token);
    return decodedToken.full_name;
  }

  public getTokenUserId(token: string): number {
    const decodedToken = this.decodeToken(token);
    return decodedToken.upn;
  }

  public getTokenEmail(token: string): string {
    const decodedToken = this.decodeToken(token);
    return decodedToken.email;
  }

  public getTokenRoles(token: string): string[] {
    const decodedToken = this.decodeToken(token);
    return decodedToken.groups;
  }

  private decodeToken(str: string): any {
    str = str.split('.')[1];

    str = str.replace('/-/g', '+');
    str = str.replace('/_/g', '/');
    switch (str.length % 4) {
      case 0:
        break;
      case 2:
        str += '==';
        break;
      case 3:
        str += '=';
        break;
      default:
        throw 'Invalid token';
    }

    str = decodeURIComponent(escape(atob(str)));

    str = JSON.parse(str);
    return str;
  }
}
