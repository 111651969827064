import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import {
  DxBoxModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxGalleryModule,
  DxLoadIndicatorModule,
  DxMultiViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { HomePageComponent } from 'src/app/website/home/components/home-page.component';
import { HomeVideoComponent } from './home-video/home-video.component';
import { SharedModule } from '../../shared/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { ProductConfiguratorComponent } from './components/product-configurator/product-configurator.component';

@NgModule({
  declarations: [HomePageComponent, HomeVideoComponent, ProductConfiguratorComponent],
  exports: [HomePageComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    LayoutModule,
    TranslocoModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxMultiViewModule,
    DxBoxModule,
    DxTextBoxModule,
    DxGalleryModule,
    DxCheckBoxModule,
    DxLoadIndicatorModule,
    RouterModule,
    SharedModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['product', 'website'],
    },
  ],
})
export class HomeModule {}
