import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-website-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class WebsiteFooterComponent {
  @Input() isMobile = false;
}
